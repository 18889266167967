/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import config from '../../op-datagrid.config.json';
import { useGetPresentationService, usePostPresentationService } from '../../hooks';
import {
  AutoGroupHeaderTemplate,
  FooterTemplate,
  LoaderTemplate,
  LoaderTemplateForRow,
  OverlayTemplate,
  ScrollPatcherTemplate,
} from '../../Templates';
import {
  applyCapitalization,
  applyClearAllFilter,
  applyFilterParams,
  applyShadesOnBookedColumn,
  applySharedColumnProperties,
  applyStyleOnChildHeaderCell,
  applyTooltips,
  applyVerticalColumns,
  getRowsToDisplay,
  gridRowIdHelper,
  gridRowSelectionHelper,
  gridRowSortHelper,
  gridStateHelper,
  gridStyleHelper,
  onCellEditingStoppedEvent,
  onCellKeyDownEvent,
  onCellMouseDownEvent,
  onCellValueChangedEvent,
  onColumnVisibleEvent,
  onFilterChangedEvent,
  onGridPreDestroyedEvent,
  onGridReadyEvent,
  onGridSizeChangedEvent,
  onModelUpdatedEvent,
  onRowDataUpdatedEvent,
  onRowSelectedEvent,
  onSelectionChangedEvent,
  onSortChangedEvent,
  onStateUpdatedEvent,
} from '../../utils';
import { Themes } from '../../types';
import '../../stylesheets/ag-theme-op2mise-2.css';
import '../../stylesheets/ag-theme-op2mise.css';
import '../../stylesheets/ag-theme-op2mise-simple.css';
import { applyCellStyle } from 'op2mise-react-widgets/OpDataGrid/utils/autoRuns/applyCellStyle.auto';

const { Constants } = config;

export function BaseGrid(props) {
  const {
    autoGroupColumnDef,
    baseGridRef,
    columns,
    columnDefinition,
    customFooterElement,
    customVerbiage,
    defaultColumnProperties,
    defaultSort,
    detailCellRenderer,
    detailCellRendererParams,
    detailRowHeight,
    disableHighlightOnBookedColumn,
    enableMultipleColumnSorting,
    enableMultipleRowSelection,
    getDataPath,
    gridHeightBuffer,
    gridLabel,
    gridName,
    groupDefaultExpanded,
    isGroupOpenByDefault,
    loading,
    masterDetail,
    noRowsOverlayComponent,
    onCellEditingStopped,
    onCellKeyDown,
    onCellMouseDown,
    onCellValueChanged,
    onColumnResized,
    onColumnVisible,
    onFilterChanged,
    onGridPreDestroyed,
    onGridReady,
    onGridSizeChanged,
    onRowDataUpdated,
    onRowSelected,
    onSelectionChanged,
    onSortChanged,
    onStateUpdated,
    overviewGridRef,
    pagination,
    paginationPageSize,
    postSortRows,
    rowClassRules,
    rowClass,
    rowId,
    rows,
    rowSelection,
    selectionColumnDef,
    setColumnDefinition,
    setIsVerticalScroll,
    sharedColumnProperties,
    showAlternativeRowHighlight,
    showRowSelectionCount,
    suppressContextMenu,
    suppressMovableColumns,
    suppressRowClickSelection,
    theme,
    treeData,
    useCheckboxForRowSelection,
  } = props;

  // (♦) Custom Hooks Definition
  const { response, getApi } = useGetPresentationService();
  const { saveApi } = usePostPresentationService();

  // (♦) State Definition
  const [autoGroupColumnDefinition, setAutoGroupColumnDefinition] = React.useState(autoGroupColumnDef);
  const [columnIsWithChildren, setColumnIsWithChildren] = React.useState(false);
  const [gridHasHorizontalScrollbar, setGridHasHorizontalScrollbar] = React.useState(false);
  const [gridHasRightPinnedColumns, setGridHasRightPinnedColumns] = React.useState(false);
  const [gridHasVerticalColumns, setGridHasVerticalColumns] = React.useState(false);
  const [gridHeaderHeight, setGridHeaderHeight] = React.useState(0);
  const [gridState, setGridState] = React.useState(null);
  const [isFirstDataRendered, setIsFirstDataRendered] = React.useState(false);
  const [isGridReady, setIsGridReady] = React.useState(false);
  const [isGridRowStillProcessing, setIsGridRowStillProcessing] = React.useState(false);
  const [isGridStillProcessing, setIsGridStillProcessing] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(paginationPageSize);
  const [rowData, setRowData] = React.useState(rows);
  const [rowsToDisplay, setRowsToDisplay] = React.useState(rows.length);
  const [selectedRows, setSelectedRows] = React.useState(0);

  // (♦) Memoized Functions/Callbacks
  const eventHandlingProps = React.useMemo(() => {
    return {
      columnDefinition,
      columnIsWithChildren,
      gridHasVerticalColumns,
      gridName,
      gridRef: baseGridRef,
      gridState,
      onCellEditingStopped,
      onCellKeyDown,
      onCellMouseDown,
      onCellValueChanged,
      onColumnVisible,
      onFilterChanged,
      onGridPreDestroyed,
      onGridReady,
      onGridSizeChanged,
      onRowDataUpdated,
      onRowSelected,
      onSelectionChanged,
      onSortChanged,
      onStateUpdated,
      setSelectedRows,
    }
  }, [
    columnDefinition,
    gridName,
    gridState,
    onCellEditingStopped,
    onCellKeyDown,
    onCellMouseDown,
    onCellValueChanged,
    onColumnVisible,
    onFilterChanged,
    onGridPreDestroyed,
    onGridReady,
    onGridSizeChanged,
    onRowDataUpdated,
    onRowSelected,
    onSelectionChanged,
    onSortChanged,
    onStateUpdated,
  ]);

  const gridWrapperStyle = React.useCallback((buffer) => gridStyleHelper({ gridHeightBuffer: buffer }), []);

  const checkForScrollbars = React.useCallback((params) => {
    const gridId = params.api.getGridId();
    const gridHorizontalScrollViewport = document.querySelector(`[grid-id="${gridId}"] .ag-body-horizontal-scroll-viewport`);
    const gridHeader = document.querySelector(`[grid-id="${gridId}"] .ag-header-viewport`);

    // Cache the computed values
    const newGridHeaderHeight = gridHeader ? gridHeader.getBoundingClientRect().height.toFixed(0) : 0;
    const hasHorizontalScrollbar = gridHorizontalScrollViewport.scrollWidth > gridHorizontalScrollViewport.clientWidth;

    // Only update state if values have changed
    if (newGridHeaderHeight !== gridHeaderHeight) {
      setGridHeaderHeight(newGridHeaderHeight);
    }

    if (hasHorizontalScrollbar !== gridHasHorizontalScrollbar) {
      setGridHasHorizontalScrollbar(hasHorizontalScrollbar);
    }

    const rightPinnedColumns = params.api.getAllGridColumns().filter(col => col.getPinned() === 'right');
    const hasRightPinned = rightPinnedColumns.length > 0;
    if (hasRightPinned !== gridHasRightPinnedColumns) {
      setGridHasRightPinnedColumns(rightPinnedColumns.length > 0);
    }
  }, [gridHeaderHeight, gridHasHorizontalScrollbar, gridHasRightPinnedColumns]);

  const rowVoidFiller = React.useCallback((params) => {
    const gridId = params.api.getGridId();
    const centerColumnViewport = document.querySelector(`[grid-id="${gridId}"] .ag-center-cols-viewport`);
    const centerHorizontalScrollViewPort = document.querySelector(`[grid-id="${gridId}"] .ag-body-horizontal-scroll-viewport`);

    /**
    * If the scroll of horizontal scroll viewport is 0 then use the scroll width
    * of the center column container viewport. This logic is necessary because
    * the scroll width of the centerColumnViewport alone is not updating its value as
    * column viewport shrink in ui
    */
    const rowWidth = centerHorizontalScrollViewPort.clientWidth
      ? centerHorizontalScrollViewPort.scrollWidth
      : centerColumnViewport.scrollWidth;

    document.querySelectorAll(`[grid-id="${gridId}"] .ag-center-cols-viewport .ag-center-cols-container .ag-row.ag-row-void-filler`)
      .forEach((row) => {
        row.style.width = `${rowWidth}px`;
      });

  }, []);

  const { rowSelectionProperties, selectionColumnProperties } = React.useMemo(() =>
    gridRowSelectionHelper(rowSelection, selectionColumnDef, enableMultipleRowSelection),
    [rowSelection, selectionColumnDef, enableMultipleRowSelection]
  );

  const handleAutoGroupColumnProperties = React.useCallback(() => {
    // check if property is defined
    if (autoGroupColumnDef) {
      if (!Object.keys(autoGroupColumnDef).length) return;

      const hasWeekVerticalColumns = columns.some(
        (column) => column.field?.includes('week') && column.field?.includes('-vertical')
      );

      setAutoGroupColumnDefinition(() => ({
        ...autoGroupColumnDef,
        headerComponent: AutoGroupHeaderTemplate,
        headerClass: `${autoGroupColumnDef.headerClass || ''} ${hasWeekVerticalColumns ? 'ag-header-none-vertical' : ''}`,
        ...(autoGroupColumnDef?.headerTooltip ? {} : { headerTooltip: autoGroupColumnDef.headerName }),
        ...(autoGroupColumnDef?.tooltipValueGetter ? {} : { tooltipValueGetter: (params) => params.value }),
        filterParams: {
          ...autoGroupColumnDef?.filterParams,
          ...(autoGroupColumnDef?.applyMiniFilterWhileTyping ? {} : { applyMiniFilterWhileTyping: true }),
        },
      }));
    }
  }, [autoGroupColumnDef, columnIsWithChildren, setAutoGroupColumnDefinition]);

  const updateRowData = React.useCallback(() => {
    let gridRowTimer;

    if (isGridReady) setIsGridRowStillProcessing(true);

    gridRowTimer = setTimeout(() => setIsGridRowStillProcessing(false), 300);

    return () => {
      clearTimeout(gridRowTimer);
    }

  }, [isGridReady]);

  // (♦) Auto Runs: Customized Functions
  const handleCapitalization = React.useCallback((columnInstance) =>
    // Auto run for capitalizing header name and header tooltip texts
    applyCapitalization({ columnInstance })
    , []);

  const handleCellStyle = React.useCallback((columnInstance) =>
    // Auto run for applying cell style based on the cell data type
    applyCellStyle({ columnInstance })
    , []);

  const handleClearAllFilter = React.useCallback((columnInstance) =>
    // Auto run for setting up Clear All Filter feature
    applyClearAllFilter({ columnInstance })
    , []);

  const handleFilterParams = React.useCallback((columnInstance) =>
    // Auto run for setting up Automatic Filter on Input Change Feature
    applyFilterParams({ columnInstance })
    , []);

  const handleBookedColumn = React.useCallback((columnInstance) =>
    // Auto run for setting background color (light/dark colors) on columns that contains `book` in the `field` column attribute
    applyShadesOnBookedColumn({ columnInstance, disableHighlightOnBookedColumn, setColumnIsWithChildren })
    , [disableHighlightOnBookedColumn]);

  const handleChildHeaderCell = React.useCallback((columnInstance) =>
    // Auto run for setting background color (pine-gree color) on CHILD header cells
    applyStyleOnChildHeaderCell({ columnInstance })
    , []);

  const handleSharedColumnProperties = React.useCallback((columnInstance) =>
    // Auto run for memoized callback to apply shared column properties accross multiple columns.
    applySharedColumnProperties({ columnInstance, sharedColumnProperties })
    , [sharedColumnProperties]);

  const handleTooltips = React.useCallback((columnInstance) =>
    // Auto run for setting up tooltips for header and row cells
    applyTooltips({ columnInstance })
    , []);

  const handleVerticalColumns = React.useCallback((columnInstance) =>
    // Auto run for applying Vertical Column Styling on Header
    applyVerticalColumns({ columnInstance, setGridHasVerticalColumns })
    , []);

  const executeAllAutoRuns = React.useCallback(() => {
    // Executes all auto-run functions
    const columnInstance = columns;
    const autoRuns = [
      handleBookedColumn,
      handleCapitalization,
      handleChildHeaderCell,
      handleClearAllFilter,
      handleFilterParams,
      handleSharedColumnProperties,
      handleTooltips,
      handleVerticalColumns,
      handleCellStyle,
    ];
    const refactoredColumns = autoRuns.reduce((column, func) => func(column), columnInstance);
    setColumnDefinition(refactoredColumns);
  }, [
    columns,
    handleBookedColumn,
    handleCapitalization,
    handleCellStyle,
    handleChildHeaderCell,
    handleClearAllFilter,
    handleFilterParams,
    handleSharedColumnProperties,
    handleTooltips,
    handleVerticalColumns,
  ]);

  // (♦) Grid Event Handler for: onCellEditingStopped
  const handleOnCellEditingStopped = React.useCallback((params) => onCellEditingStoppedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onCellKeyDown event
  const handleOnCellKeyDown = React.useCallback((params) => onCellKeyDownEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onCellMouseDown event
  const handleOnCellMouseDownEvent = React.useCallback((params) => onCellMouseDownEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onCellValueChanged event
  const handleOnCellValueChanged = React.useCallback((params) => onCellValueChangedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onColumnVisible event
  const handleOnColumnVisible = React.useCallback((params) => onColumnVisibleEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onGridSizeChanged event
  const handleOnGridSizeChanged = React.useCallback((params) => onGridSizeChangedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onModelUpdated event
  const handleOnModelUpdated = React.useCallback((params) => onModelUpdatedEvent({ params, rowVoidFiller, setRowsToDisplay, ...eventHandlingProps }), [eventHandlingProps, rowVoidFiller, rowsToDisplay]);
  
  // (♦) Grid Event Handler for: onRowDataUpdated event
  const handleOnRowDataUpdated = React.useCallback((params) => onRowDataUpdatedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onRowSelected event
  const handleOnRowSelected = React.useCallback((params) => onRowSelectedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onSelectionChanged event  
  const handleOnSelectionChanged = React.useCallback((params) => onSelectionChangedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onSortChanged event
  const handleOnSortChanged = React.useCallback((params) => onSortChangedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onStateUpdated event
  const handleOnStateUpdated = React.useCallback((params) => onStateUpdatedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // (♦) Grid Event Handler for: onColumnResized event
  const handleOnColumnResized = React.useCallback((params) => {
    if (onColumnResized) onColumnResized(params);
    checkForScrollbars(params);
    rowVoidFiller(params);
  }, [checkForScrollbars]);

  // (♦) Grid Event Handler for: onFilterChanged event
  const handleOnFilterChanged = React.useCallback((params) => {
    onFilterChangedEvent({ params, ...eventHandlingProps });
    checkForScrollbars(params);
  }, [eventHandlingProps]);

  // (♦) Grid Event Handler for: onGridPreDestroyed event
  const handleOnGridPreDestroyed = React.useCallback((params) => {
    if (gridName) {
      const requestBody = {
        grid: gridName,
        state: JSON.stringify(params.state),
      };
      saveApi('User/SaveGridState', requestBody);
    }
    onGridPreDestroyedEvent({ params, ...eventHandlingProps });
  }, [eventHandlingProps, gridName]);

  // (♦) Grid Event Handler for: onGridReady event
  const handleOnGridReady = React.useCallback((params) => {
    onGridReadyEvent({ params, ...eventHandlingProps });
    setIsGridReady(true);
    checkForScrollbars(params);
    /**
     * Filler also needs to bind as event listener to the scrollbar
     * because ag-grid dynamically render rows while scrolling 
     * so filler only fills what's in the viewport
     */
    params.api.addEventListener('bodyScroll', rowVoidFiller);
    rowVoidFiller(params);
  }, [columnIsWithChildren, gridHasVerticalColumns, eventHandlingProps, checkForScrollbars]);

  // (♦) Use Effect Hooks
  React.useEffect(() => {
    /**
     * Side-effect responsible for invoking all auto-run functions
     * Auto-run functions are customized features.
     * See: /utils/autoRuns
     */
    executeAllAutoRuns();
  }, [columns]);

  React.useEffect(() => {
    /**
     * Side-effect for fetching Grid State
     * Pre-configures Grid State before rendering into the Client
     */
    if (gridName) {
      getApi('User/GetGridState', { grid: gridName });
    } else {
      setGridState(true);
    }
  }, [gridName]);

  React.useEffect(() => {
    /**
     * Updates columnDefs gridOption when column changes
     */
    baseGridRef.current?.api?.setGridOption('columnDefs', columnDefinition);
  }, [columnDefinition]);

  React.useEffect(() => {
    /**
     * Side-effect for capturing the response body from
     * the GetGridState API Call
     */
    if (response) {

      const state = response.state ? JSON.parse(response.state) : {};

      if (response.state) {

        gridRowSortHelper(
          state,
          defaultSort,
          baseGridRef,
          columnDefinition,
          setAutoGroupColumnDefinition,
        )

        gridStateHelper(state, baseGridRef);

      }
      setGridState(state);

    }
  }, [response]);

  React.useEffect(() => {
    /**
     * Side-effect for handling auto grouped columns/rows
     */
    handleAutoGroupColumnProperties();
  }, [autoGroupColumnDef]);


  React.useEffect(() => {
    /**
     * Base Grid Loader
     * Side-effect responsible for Invoking Loading effect from external factor (API call)
     * Decides whether to use Grid level or Row level loaders
     */

    let timerForGrid;
    let timerForRow;
    const isGridEstablished = isFirstDataRendered;
    const isGridLoadingExternally = loading;
    const isGridLoadingInternally = !isGridReady;
    const isGridStateFetched = gridState;
    const shouldGridLoadWhenLoadingExternally = isGridLoadingExternally && !isGridEstablished;

    if (isGridLoadingExternally && !shouldGridLoadWhenLoadingExternally) {
      setIsGridRowStillProcessing(true);
    } else {
      timerForRow = setTimeout(() => setIsGridRowStillProcessing(false), 300);
    }

    if (!shouldGridLoadWhenLoadingExternally && !isGridLoadingInternally && isGridStateFetched) {
      timerForGrid = setTimeout(() => setIsGridStillProcessing(false), 900);
    } else {
      setIsGridStillProcessing(true);
    }

    return () => {
      clearTimeout(timerForGrid);
      clearTimeout(timerForRow);
    };
  }, [
    isFirstDataRendered,
    isGridReady,
    gridState,
    loading,
  ]);

  React.useEffect(() => {
    /**
     * Side-effect responsible for hiding the default overlay message for empty rows
     * Prevents overlapping with BootstrapSpinner
     */
    if (loading || !isGridReady) {
      if (baseGridRef.current?.api) baseGridRef.current?.api.hideOverlay();
    }
  }, [loading, isGridReady]);

  React.useEffect(() => {
    /**
     * Side-effect responsible for setting up row-data for the Grid
     */

    updateRowData();

    const rowLength = rows.length;

    setRowData(rows);
    setRowsToDisplay(rowLength);

    if (rowLength > 200) {
      // Dynamically set a new page size if number of rows start to exceed 200
      const newPageSize = (Math.ceil(rowLength / 100) * 100);
      setPageSize(newPageSize);
    };

  }, [rows, updateRowData]);

  return (
    <React.Fragment>
      <span style={{ color: '#6d6d73', fontSize: '14px', fontWeight: 400, marginBottom: '5px' }}>{gridLabel}</span>
      <div className={theme === Themes.PRIMARY ? columnIsWithChildren ? 'ag-theme-op2mise-2' : 'ag-theme-op2mise' : 'ag-theme-op2mise-simple'} style={gridWrapperStyle(gridHeightBuffer)}>
        <LoaderTemplate {...{
          isLoading: isGridStillProcessing,
          height: gridWrapperStyle(gridHeightBuffer).height,
        }} />
        <LoaderTemplateForRow {...{
          isLoading: isGridRowStillProcessing && !isGridStillProcessing,
          height: gridWrapperStyle(gridHeightBuffer + 51).height,
        }} />
        <ScrollPatcherTemplate
          isVisible={isGridReady && !loading && gridHasHorizontalScrollbar && !gridHasRightPinnedColumns}
          gridHeaderHeight={gridHeaderHeight}
          theme={theme}
        />
        <AgGridReact
          alignedGrids={[overviewGridRef]}
          autoGroupColumnDef={autoGroupColumnDefinition}
          columnDefs={columnDefinition}
          cellSelection={false}
          defaultColDef={defaultColumnProperties}
          detailCellRenderer={detailCellRenderer}
          detailCellRendererParams={detailCellRendererParams}
          detailRowHeight={detailRowHeight}
          getRowId={(params) => typeof rowId === 'string' ? gridRowIdHelper(params, rowId): gridRowIdHelper(params, rowId(params))}
          gridId={window.crypto.randomUUID()}
          groupDefaultExpanded={groupDefaultExpanded}
          isGroupOpenByDefault={isGroupOpenByDefault}
          masterDetail={masterDetail}
          noRowsOverlayComponent={() => (<OverlayTemplate customVerbiage={customVerbiage} />)}
          onColumnVisible={handleOnColumnVisible}
          onColumnResized={handleOnColumnResized}
          onFilterChanged={handleOnFilterChanged}
          onFirstDataRendered={(params) => setIsFirstDataRendered(true)}
          onGridPreDestroyed={handleOnGridPreDestroyed}
          onGridReady={handleOnGridReady}
          onGridSizeChanged={handleOnGridSizeChanged}
          onModelUpdated={handleOnModelUpdated}
          onRowDataUpdated={handleOnRowDataUpdated}
          onRowSelected={handleOnRowSelected}
          onRowGroupOpened={(params) => {
            const rowsToDisplay = getRowsToDisplay(params);
            const isExpanded = rowsToDisplay.find((obj) => obj.expanded);
            if (isExpanded) {
              setIsVerticalScroll(true);
            } else {
              setIsVerticalScroll(false);
            }
          }}
          onCellEditingStopped={handleOnCellEditingStopped}
          onCellKeyDown={handleOnCellKeyDown}
          onCellMouseDown={handleOnCellMouseDownEvent}
          onCellValueChanged={handleOnCellValueChanged}
          onSelectionChanged={handleOnSelectionChanged}
          onSortChanged={handleOnSortChanged}
          onStateUpdated={handleOnStateUpdated}
          pagination={pagination}
          paginationPageSize={pageSize}
          postSortRows={postSortRows}
          ref={baseGridRef}
          rowData={rowData}
          rowClass={rowClass}
          rowClassRules={{
            ...rowClassRules,
            ...(showAlternativeRowHighlight && {
              'ag-row-alternate': (params) => params && params.rowIndex % 2 !== 0,
            }),
            'ag-row-void-filler': (params) => params.data
          }}
          rowSelection={rowSelectionProperties}
          selectionColumnDef={selectionColumnProperties}
          rowMultiSelectWithClick={enableMultipleRowSelection}
          suppressContextMenu={suppressContextMenu}
          suppressMovableColumns={suppressMovableColumns}
          suppressRowClickSelection={useCheckboxForRowSelection || suppressRowClickSelection}
          treeData={treeData}
          {...enableMultipleColumnSorting && { multiSortKey: 'ctrl' }}
          {...(treeData && getDataPath) && { getDataPath }}
          {...Constants.GridOptions}
        />
      </div>
      <FooterTemplate {...{
        loading: isGridStillProcessing,
        pagination,
        rowsToDisplay,
        customFooterElement,
        baseGridRef,
      }} />
      {showRowSelectionCount && (
        <p style={{ marginTop: '15px', color: '#6d6d73', fontSize: '12px', fontWeight: '400', letterSpacing: 0, lineHeight: '18px' }}>
          {selectedRows} rows selected
        </p>
      )}
    </React.Fragment>
  );
}
