import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import useStore from 'store/AccountStore';
import AuthService from 'core/auth.service';
import styles from './sidebar.module.css';
import usersIcon from '../../assets/icons/Users.png';
import op2miseLogo from '../../assets/icons/op2mise.svg';
import LogoCollapsed from '../../assets/icons/op2miseLogoSmall.svg';
import settingsIcon from '../../assets/icons/Settings-icon.svg';
import planningIcon from '../../assets/icons/Planning.svg';
import channelIcon from '../../assets/icons/Channel-icon.svg';
import BreakArchitectureIcon from '../../assets/icons/BreakArchitectureIcon.svg';
import ChannelConfigurationIcon from '../../assets/icons/channel-configuration.svg';
import ChannelArchitectureIcon from '../../assets/icons/channel-architecture.svg';
import priotisationIcon from '../../assets/icons/Prioritisation.svg';
import scheduleIcon from '../../assets/icons/Schedule-icon.svg';
import titleGroupIcon from '../../assets/icons/TitleGroups.svg';
import submissionIcon from '../../assets/icons/Submission-icon.svg';
import organisationIcon from '../../assets/icons/Organisation-icon.svg';
import channelSidebarIcon from '../../assets/icons/channel-sidebar.svg';
import channelGroupSidebarIcon from '../../assets/icons/channelgroup-sidebar.svg';
import titleExclusionIcon from '../../assets/icons/Title-Exclusions.svg';
import attributesOverrideIcon from '../../assets/icons/Attributes-Overrides.svg';
import emailTriggerIcon from '../../assets/icons/Email-Trigger.svg';
import chevRightIcon from '../../assets/icons/Chevron-Right.png';
import schedulesCreate from '../../assets/icons/Schedules-Generate-Create.svg';
import schedulesSubmit from '../../assets/icons/Schedules_Submit.svg';
import schedulesView from '../../assets/icons/Schedules_View.svg';
import { titleInclusionsIcon } from '../../assets';
import { AppModules } from 'enums';
import { getAllLastAccessed, saveAllLastAccessed } from 'utils';
import { removeAllLastAccessed } from 'utils';
import { SuperAdminModules } from 'enums';
import libraryIcon from '../../assets/icons/library.svg';
import libraryProgramsIcon from '../../assets/icons/library-programs.svg';
import { interstitials } from '../../assets';
import rightsIcon from '../../assets/icons/Rights_Management.svg';
import ChevronComponent from './ChevronComponent';

const { forwardRef, useImperativeHandle } = React;
const SidebarComponent = forwardRef(
  (
    {
      toggleSidebar,
      callbackUserLogout,
      isProfileUpdated,
      setIsProfileUpdated,
    },
    ref
  ) => {
    const { user, setUser } = useStore((state) => state);
    const profileComponentRef = useRef();
    useImperativeHandle(ref, () => ({
      updateSidebarProfile(id) {
        profileComponentRef.current.updateSidebarProfile(id);
      },
    }));
    const location = useLocation();
    const [sidebarState, setSidebarState] = useState(false);
    const [settingsLinks, setSettingsLinks] = useState(false);
    const [planningLinks, setPlanningLinks] = useState(false);
    const [scheduleLinks, setScheduleLinks] = useState(false);
    const [channelSetupLinks, setChannelSetupLinks] = useState(false);
    const [profileLinkState, setProfileLinkState] = useState(false);
    const [libraryLinks, setLibraryLinks] = useState(false);

    const [roleID, setRoleID] = useState();
    const [logo, setLogo] = useState();
    const [collapsedLogo, setCollapsedLogo] = useState(LogoCollapsed);

    const getUser = async () => {
      const userProfile = user;
      setRoleID(userProfile.roleId);
      if (
        userProfile.organisationBase64Logo !== null &&
        userProfile.organisationBase64Logo !== ''
      ) {
        setLogo(userProfile.organisationBase64Logo);
        setCollapsedLogo(userProfile.organisationBase64Logo);
      } else {
        setLogo(op2miseLogo);
        setCollapsedLogo(LogoCollapsed);
      }
    };

    const toggleSidebarState = (state) => {
      setSidebarState(state);
      toggleSidebar(state);
    };

    const showSettingsLinks = () => {
      setSettingsLinks(!settingsLinks);
    };

    const showScheduleLinks = () => {
      setScheduleLinks(!scheduleLinks);
    };

    const showPlanningLinks = () => {
      setPlanningLinks(!planningLinks);
    };

    const showChannelSetupLinks = () => {
      setChannelSetupLinks(!channelSetupLinks);
    };

    const showLibraryLinks = () => {
      setLibraryLinks(!libraryLinks);
    }

    const hideProfileLink = (state) => {
      setProfileLinkState(state);
    };

    const getBrowserWidth = () =>
      Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );

    useEffect(() => {
      const userProfile = AuthService.getUserIdentity();
      if (Object.keys(user).length == 0 || user.userId != userProfile.userId)
        setUser(userProfile);
      else getUser();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
      const handleResize = () => {
        if (getBrowserWidth() < 800) {
          toggleSidebarState(true);
        } else {
          toggleSidebarState(false);
        }
      };

      window.addEventListener('resize', handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
      if (getBrowserWidth() < 800) {
        toggleSidebarState(true);
      } else {
        toggleSidebarState(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOrgAdminModules = (menu, item) => {
      const menuItem = AppModules[menu]
      const module = menuItem.subModules ? menuItem.subModules.find((module) => module.id === item) : menuItem;
      return module;
    }

    const getSuperAdminModule = (menu, item) => {
      const menuItem = SuperAdminModules[menu]
      const module = menuItem.subModules ? menuItem.subModules.find((module) => module.id === item) : menuItem;
      return module;
    }

    const handleMenuItemClick = ({ menu, item }) => {
      const module = user.roleId === 1 ? getSuperAdminModule(menu, item) : getOrgAdminModules(menu, item);
      if (module) {
        const payload = {
          organization: user.organisationName,
          activeFunction: menu,
          menuItem: module.name,
        }
        saveAllLastAccessed(payload);
      }
    }

    return (
      <div className="position-relative vh-100">
        <div className={styles.sidebarHeader}>
          {logo && (
            <img
              className={sidebarState ? styles.vaiLogoSmall : styles.vaiLogo}
              src={sidebarState ? collapsedLogo : logo}
              alt="op2miseLogo"
            />
          )}
        </div>
        <div
          id="scrollbarStyle"
          className={` 
          // sidebarState ? styles.sidebarStateListDiv : styles.sideBarListDiv,
          ${!profileLinkState
              ? styles.sidebarStateListDiv
              : styles.sideBarListDiv
            }  scrollbarStyle`}
        >
          <div className={styles.sidebarList}>
            {(roleID === 2 || roleID === 3) && (
              <>
                {/* CHANNEL SETUP */}
                <div
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  title={sidebarState ? 'Channel Setup' : null}
                  style={{ cursor: 'pointer' }}
                  onClick={showChannelSetupLinks}
                  onKeyDown={showChannelSetupLinks}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      className={sidebarState ? styles.vaiCenterText : null}
                      src={channelIcon}
                      alt="Channel Icon"
                      style={{ marginLeft: '10px' }}
                    />
                    <span
                      className={`${sidebarState ? styles.hideText : null
                        } mx-3 `}
                    >
                      Channel Setup
                    </span>
                  </div>
                  <img
                    className={`${styles.vaiChevRight} ${channelSetupLinks ? styles.vaiChevRightRotate : null
                      } ${sidebarState ? styles.hideText : null} mx-3 `}
                    src={chevRightIcon}
                    title={channelSetupLinks ? 'Show less' : 'Show more'}
                  />
                </div>
                <div
                  style={{
                    display: channelSetupLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '15px',
                  }}
                >
                  {(roleID === 2 || roleID === 3) && (
                    <>
                      {/* CONFIGURATION */}
                      <NavLink
                        title={sidebarState ? 'Configuration' : null}
                        className={(navData) =>
                          `${navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        onClick={() => { handleMenuItemClick({ menu: 'CHANNEL_SETUP', item: 'configuration' }) }}
                        to={`${process.env.REACT_APP_SUBFOLDER}/channel-profiles`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            // marginBottom: '5px',
                          }}
                          src={ChannelConfigurationIcon}
                          alt="ChannelConfigurationIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Configuration
                        </span>
                      </NavLink>

                      {/* ARCHITECTURE */}
                      <NavLink
                        title={sidebarState ? 'Architecture' : null}
                        className={(navData) =>
                          `${navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        onClick={() => { handleMenuItemClick({ menu: 'CHANNEL_SETUP', item: 'architecture' }) }}
                        to={`${process.env.REACT_APP_SUBFOLDER}/architecture`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '14px' : '9px',
                            // marginBottom: '4px',
                          }}
                          src={ChannelArchitectureIcon}
                          alt="ChannelArchitectureIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Architecture
                        </span>
                      </NavLink>

                      {/* BREAK ARCHITECTURE */}
                      <NavLink
                        title={sidebarState ? 'Break Patterns' : null}
                        className={(navData) =>
                          `${navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        onClick={() => { handleMenuItemClick({ menu: 'CHANNEL_SETUP', item: 'break-patterns' }) }}
                        to={`${process.env.REACT_APP_SUBFOLDER}/break-patterns`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '14px' : '12px',
                            paddingRight: '1px',
                            // marginBottom: '2px',
                          }}
                          src={BreakArchitectureIcon}
                          alt="BreakArchitectureIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Break Patterns
                        </span>
                      </NavLink>
                    </>
                  )}
                </div>
                {/* END CHANNEL SETUP */}

                {/* LIBRARY */}
                <div
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  title={sidebarState ? 'Library' : null}
                  style={{ cursor: 'pointer' }}
                  onClick={showLibraryLinks}
                  onKeyDown={showLibraryLinks}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      className={sidebarState ? styles.vaiCenterText : null}
                      src={libraryIcon}
                      alt="Library Icon"
                      style={{ marginLeft: '10px' }}
                    />
                    <span
                      className={`${sidebarState ? styles.hideText : null
                        } mx-3 `}
                    >
                      Library
                    </span>
                  </div>
                  <img
                    className={`${styles.vaiChevRight} ${libraryLinks ? styles.vaiChevRightRotate : null
                      } ${sidebarState ? styles.hideText : null} mx-3 `}
                    src={chevRightIcon}
                    title={libraryLinks ? 'Show less' : 'Show more'}
                  />
                </div>
                <div
                  style={{
                    display: libraryLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '15px',
                  }}
                >
                  {(roleID === 2 || roleID === 3) && (
                    <>
                      {/* PROGRAMS */}
                      <NavLink
                        title={sidebarState ? 'Programs' : null}
                        className={(navData) =>
                          `${navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        onClick={() => { handleMenuItemClick({ menu: 'LIBRARY', item: 'programs' }) }}
                        to={`${process.env.REACT_APP_SUBFOLDER}/library/programs`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            // marginBottom: '5px',
                          }}
                          src={libraryProgramsIcon}
                          alt="Library Programs Icon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Programs
                        </span>
                      </NavLink>
                      {/* INTERSTITIAL */}
                      <NavLink
                        title={sidebarState ? 'Interstitials' : null}
                        className={(navData) =>
                          `${navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        onClick={() => { handleMenuItemClick({ menu: 'LIBRARY', item: 'interstitials' }) }}
                        to={`${process.env.REACT_APP_SUBFOLDER}/library/interstitials`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            // marginBottom: '5px',
                          }}
                          src={interstitials}
                          alt="Library Interstitials Icon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Interstitials
                        </span>
                      </NavLink>
                      {/* TITLE GROUPS */}
                      <NavLink
                        title={sidebarState ? 'Title Groups' : null}
                        className={(navData) =>
                          `${navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        onClick={() => { handleMenuItemClick({ menu: 'PLANNING', item: 'title-groups' }) }}
                        to={`${process.env.REACT_APP_SUBFOLDER}/library/title-groups`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            // marginBottom: '5px',
                          }}
                          src={titleGroupIcon}
                          alt="titlegroups"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Title Groups
                        </span>
                      </NavLink>
                    </>
                  )}
                </div>
                {/* END LIBRARY */}

                {/* RIGHTS */}
                <NavLink
                  className={(navData) =>
                    `${navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  title={sidebarState ? 'Rights' : null}
                  onClick={() => { handleMenuItemClick({ menu: 'RIGHTS', item: 'rights' }) }}
                  to={`${process.env.REACT_APP_SUBFOLDER}/rights`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={rightsIcon}
                    alt="rights-icon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Rights
                  </span>
                </NavLink>
                {/* END RIGHTS */}

                {/* PLANNING */}
                <div
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  title={sidebarState ? 'Planning' : null}
                  style={{ cursor: 'pointer' }}
                  onClick={showPlanningLinks}
                  onKeyDown={showPlanningLinks}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      className={sidebarState ? styles.vaiCenterText : null}
                      src={planningIcon}
                      alt="PlanningIcon"
                      style={{ marginLeft: '10px' }}
                    />
                    <span
                      className={`${sidebarState ? styles.hideText : null
                        } mx-3`}
                    >
                      Planning
                    </span>
                  </div>
                  <img
                    className={`${styles.vaiChevRight} ${planningLinks ? styles.vaiChevRightRotate : null
                      } ${sidebarState ? styles.hideText : null} mx-3 `}
                    src={chevRightIcon}
                    title={planningLinks ? 'Show less' : 'Show more'}
                  />
                </div>
                <div
                  style={{
                    display: planningLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '15px',
                  }}
                >
                  {(roleID === 2 || roleID === 3) && (
                    <>
                      <NavLink
                        title={sidebarState ? 'Inclusions' : null}
                        className={(navData) =>
                          `${navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        onClick={() => { handleMenuItemClick({ menu: 'PLANNING', item: 'inclusions' }) }}
                        to={`${process.env.REACT_APP_SUBFOLDER}/inclusion`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '3px',
                            marginRight: '5px',
                          }}
                          src={titleInclusionsIcon}
                          alt="titleInclusionsIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Inclusions
                        </span>
                      </NavLink>

                      <NavLink
                        title={sidebarState ? 'Exclusions' : null}
                        className={(navData) =>
                          `${navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        onClick={() => { handleMenuItemClick({ menu: 'PLANNING', item: 'exclusions' }) }}
                        to={`${process.env.REACT_APP_SUBFOLDER}/title-exclusions`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={titleExclusionIcon}
                          alt="titleexclusionss"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Exclusions
                        </span>
                      </NavLink>

                      {/* <NavLink
                        title={sidebarState ? 'Attributes Override' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/attributes-override`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={attributesOverrideIcon}
                          alt="attributesOverride"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Attributes Override
                        </span>
                      </NavLink> */}
                    </>
                  )}
                </div>

                {/** SCHEDULES */}
                <div
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  title={sidebarState ? 'Schedules' : null}
                  style={{ cursor: 'pointer' }}
                  onClick={showScheduleLinks}
                  onKeyDown={showScheduleLinks}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      className={sidebarState ? styles.vaiCenterText : null}
                      src={scheduleIcon}
                      alt="scheduleIcon"
                      style={{ marginLeft: '10px' }}
                    />
                    <span
                      className={`${sidebarState ? styles.hideText : null
                        } mx-3`}
                    >
                      Schedules
                    </span>
                  </div>
                  <img
                    className={`${styles.vaiChevRight} ${scheduleLinks ? styles.vaiChevRightRotate : null
                      } ${sidebarState ? styles.hideText : null} mx-3`}
                    src={chevRightIcon}
                    title={scheduleLinks ? 'Show less' : 'Show more'}
                  />
                </div>
                <div
                  style={{
                    display: scheduleLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '15px',
                  }}
                >
                  <NavLink
                    title={sidebarState ? 'View' : null}
                    className={(navData) =>
                      `${navData.isActive ? styles.activeLink : styles.link
                      } nav-item nav-link px-0 py-2 position-relative  ${location.pathname ===
                        `${process.env.REACT_APP_SUBFOLDER}/view-schedules`
                        ? styles.activeLink
                        : ''
                      }`
                    }
                    onClick={() => { handleMenuItemClick({ menu: 'SCHEDULES', item: 'view' }) }}
                    to={`${process.env.REACT_APP_SUBFOLDER}/view-schedules`}
                  >
                    <img
                      className={sidebarState && styles.centerText}
                      style={{ marginLeft: '15px', marginBottom: '5px' }}
                      src={schedulesView}
                      alt="schedulesViewIcon"
                    />
                    <span
                      className={` ${sidebarState && styles.hideText} mx-3`}
                    >
                      View
                    </span>
                  </NavLink>
                  <NavLink
                    title={sidebarState ? 'New' : null}
                    className={(navData) =>
                      `${navData.isActive ? styles.activeLink : styles.link
                      } nav-item nav-link px-0 py-2 position-relative  ${location.pathname ===
                        `${process.env.REACT_APP_SUBFOLDER}/scheduler`
                        ? styles.activeLink
                        : ''
                      }`
                    }
                    onClick={() => { handleMenuItemClick({ menu: 'SCHEDULES', item: 'new' }) }}
                    to={`${process.env.REACT_APP_SUBFOLDER}/schedule`}
                  >
                    <img
                      className={sidebarState && styles.centerText}
                      style={{ marginLeft: '15px', marginBottom: '5px' }}
                      src={schedulesCreate}
                      alt="schedulesCreate"
                    />
                    <span
                      className={` ${sidebarState && styles.hideText} mx-3`}
                    >
                      New
                    </span>
                  </NavLink>
                  <NavLink
                    title={sidebarState ? 'Submission' : null}
                    className={(navData) =>
                      `${navData.isActive ? styles.activeLink : styles.link
                      } nav-item nav-link px-0 py-2 position-relative  ${location.pathname ===
                        `${process.env.REACT_APP_SUBFOLDER}/submission-scheduler`
                        ? styles.activeLink
                        : ''
                      }`
                    }
                    onClick={() => { handleMenuItemClick({ menu: 'SCHEDULES', item: 'submission' }) }}
                    to={`${process.env.REACT_APP_SUBFOLDER}/submission`}
                  >
                    <img
                      className={sidebarState && styles.centerText}
                      style={{ marginLeft: '15px', marginBottom: '5px' }}
                      src={schedulesSubmit}
                      alt="submissionIcon"
                    />
                    <span
                      className={` ${sidebarState && styles.hideText} mx-3`}
                    >
                      Submission
                    </span>
                  </NavLink>
                </div>
              </>
            )}
            {roleID === 2 && (
              <>
                <div
                  title={sidebarState ? 'Settings' : null}
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  style={{ cursor: 'pointer' }}
                  onClick={showSettingsLinks}
                  onKeyDown={showSettingsLinks}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      className={sidebarState ? styles.vaiCenterText : null}
                      src={settingsIcon}
                      alt="SettingsIcon"
                      style={{ marginLeft: '10px' }}
                    />
                    <span
                      className={` ${sidebarState ? styles.hideText : null
                        } mx-3`}
                    >
                      Settings
                    </span>
                  </div>
                  <img
                    className={`${styles.vaiChevRight} ${settingsLinks ? styles.vaiChevRightRotate : null
                      }  ${sidebarState ? styles.hideText : null} mx-3`}
                    src={chevRightIcon}
                    title={settingsLinks ? 'Show less' : 'Show more'}
                  />
                </div>
                <div
                  style={{
                    display: settingsLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '12px',
                  }}
                >
                  <NavLink
                    title={sidebarState ? 'Users' : null}
                    className={(navData) =>
                      `${navData.isActive ? styles.activeLink : styles.link
                      } nav-item nav-link px-0 py-2 position-relative`
                    }
                    onClick={() => { handleMenuItemClick({ menu: 'SETTINGS', item: 'users' }) }}
                    to={`${process.env.REACT_APP_SUBFOLDER}/users`}
                  >
                    <img
                      className={sidebarState && styles.centerText}
                      style={{
                        marginLeft: sidebarState ? '15px' : '10px',
                        marginBottom: '5px',
                      }}
                      src={usersIcon}
                      alt="UsersIcon"
                    />
                    <span
                      className={` ${sidebarState && styles.hideText} mx-3`}
                    >
                      Users
                    </span>
                  </NavLink>

                  {/* {roleID !== 2 && (
                    <>
                      <NavLink
                        title={sidebarState ? 'Channel Groups' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/channel-groups`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={channelGroupSidebarIcon}
                          alt="channelGroupIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Channel Groups
                        </span>
                      </NavLink>

                      <NavLink
                        title={sidebarState ? 'Channels' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/channels`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={channelSidebarIcon}
                          alt="channelIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Channels
                        </span>
                      </NavLink>
                    </>
                  )} */}
                </div>
              </>
            )}
            {roleID === 1 && (
              <>
                <NavLink
                  title={sidebarState ? 'Organisations' : null}
                  className={(navData) =>
                    `${navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  onClick={() => { handleMenuItemClick({ menu: 'ORGANISATIONS' }) }}
                  to={`${process.env.REACT_APP_SUBFOLDER}/organisation`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={organisationIcon}
                    alt="organisationIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Organisations
                  </span>
                </NavLink>

                <NavLink
                  title={sidebarState ? 'Users' : null}
                  className={(navData) =>
                    `${navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  onClick={() => { handleMenuItemClick({ menu: 'USERS' }) }}
                  to={`${process.env.REACT_APP_SUBFOLDER}/users`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={usersIcon}
                    alt="usersIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Users
                  </span>
                </NavLink>

                <NavLink
                  title={sidebarState ? 'Channel Groups' : null}
                  className={(navData) =>
                    `${navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  onClick={() => { handleMenuItemClick({ menu: 'CHANNEL_GROUPS' }) }}
                  to={`${process.env.REACT_APP_SUBFOLDER}/channel-groups`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '5px', marginBottom: '5px' }}
                    src={channelGroupSidebarIcon}
                    alt="channelGroupIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Channel Groups
                  </span>
                </NavLink>

                <NavLink
                  title={sidebarState ? 'Channels' : null}
                  className={(navData) =>
                    `${navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  onClick={() => { handleMenuItemClick({ menu: 'CHANNELS' }) }}
                  to={`${process.env.REACT_APP_SUBFOLDER}/channels`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={channelSidebarIcon}
                    alt="channelIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Channels
                  </span>
                </NavLink>

                <div
                  title={sidebarState ? 'Settings' : null}
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  style={{ cursor: 'pointer' }}
                  onClick={showSettingsLinks}
                  onKeyDown={showSettingsLinks}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      className={sidebarState ? styles.vaiCenterText : null}
                      src={settingsIcon}
                      alt="SettingsIcon"
                      style={{ marginLeft: '10px' }}
                    />
                    <span
                      className={`${sidebarState ? styles.hideText : null
                        } mx-3`}
                    >
                      Settings
                    </span>
                  </div>
                  <img
                    className={`${styles.vaiChevRight} ${settingsLinks ? styles.vaiChevRightRotate : null
                      }  ${sidebarState ? styles.hideText : null} mx-3`}
                    src={chevRightIcon}
                    title={settingsLinks ? 'Show less' : 'Show more'}
                  />
                </div>
                <div
                  style={{
                    display: settingsLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '12px',
                  }}
                >
                  <NavLink
                    title={sidebarState ? 'Email Triggers' : null}
                    className={(navData) =>
                      `${navData.isActive ? styles.activeLink : styles.link
                      } nav-item nav-link px-0 py-2 position-relative`
                    }
                    onClick={() => { handleMenuItemClick({ menu: 'SETTINGS', item: 'email-triggers' }) }}
                    to={`${process.env.REACT_APP_SUBFOLDER}/email-triggers`}
                  >
                    <img
                      className={sidebarState && styles.centerText}
                      style={{
                        marginLeft: sidebarState ? '15px' : '10px',
                        marginBottom: '5px',
                      }}
                      src={emailTriggerIcon}
                      alt="emailTriggerIcon"
                    />
                    <span
                      className={` ${sidebarState && styles.hideText} mx-3`}
                    >
                      Email Triggers
                    </span>
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>

        <div>
          <ChevronComponent
            toggleSidebar={toggleSidebarState}
          />
        </div>
      </div>
    );
  }
);
SidebarComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  callbackUserLogout: PropTypes.func.isRequired,
};
SidebarComponent.displayName = 'SidebarComponent';
export default SidebarComponent;
