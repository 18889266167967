import React, { useEffect, useState } from 'react';

import styles from './EmailTrigger.module.css';

import BannerComponent from '../_reusable/banner/BannerComponent';
import AuthenticationComponent from './AuthenticationComponent';
import GeneratingSchedulesComponent from './GeneratingSchedulesComponent';
import SubmittingSchedulesComponent from './SubmittingSchedulesComponent';
import GeneratingSchedulesSendTestModalComponent from './modals/GeneratingSchedulesSendTestModalComponent';
import EmailTriggerService from './EmailTriggerService';
import SubmittingSchedulesSendTestModalComponent from './modals/SubmittingSchedulesSendATestMailModalComponent';
import useNotification from 'store/NotificationStore';
import Header from 'shared/components/header/Header';

const EmailTriggerComponent = () => {
  const { success } = useNotification((state) => state);
  const [selectedTab, setSelectedTab] = useState('Generating schedules');
  const [
    generatingSchedulesParameters,
    setGeneratingSchedulesParameters,
  ] = useState({ generating: true });
  const [
    generatingSchedulesErrorFields,
    setGeneratingSchedulesErrorFields,
  ] = useState({});
  const [
    showGeneratingScheduleModal,
    setShowGeneratingScheduleModal,
  ] = useState(false);
  const [
    submittingSchedulesParameters,
    setSubmittingSchedulesParameters,
  ] = useState({ generating: false });
  const [
    submittingSchedulesErrorFields,
    setSubmittingSchedulesErrorFields,
  ] = useState({});
  const [
    showSubmittingScheduleModal,
    setShowSubmittingScheduleModal,
  ] = useState(false);
  const [isUpdatingGenerating, setIsUpdatingGenerating] = useState(false);
  const [isUpdatingSubmitting, setIsUpdatingSubmitting] = useState(false);

  const handleSetGeneratingSchedulesParameters = (e) => {
    setGeneratingSchedulesParameters({
      ...generatingSchedulesParameters,
      [e.target.name]: e.target.value,
    });
  };

  const handleGeneratingSchedulesFormValidation = () => {
    const recipients = generatingSchedulesParameters.recipients
      ? generatingSchedulesParameters.recipients.split(';')
      : [];
    const emailRegex = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/;
    const isRecipientsValid = recipients.every((o) => emailRegex.test(o));

    if (
      !generatingSchedulesParameters.subject ||
      !generatingSchedulesParameters.recipients ||
      (!isRecipientsValid && generatingSchedulesParameters.recipients)
    ) {
      setGeneratingSchedulesErrorFields({
        subjectState: !generatingSchedulesParameters.subject,
        subjectErrorMessage: 'Subject is required',
        recipientsState:
          !generatingSchedulesParameters.recipients ||
          (!isRecipientsValid && generatingSchedulesParameters.recipients),
        recipientsErrorMessage:
          !isRecipientsValid && generatingSchedulesParameters.recipients
            ? 'Check the recipient(s) if it is a valid email format'
            : 'Recipient(s) is required',
      });
      return false;
    }

    setGeneratingSchedulesErrorFields({});
    return true;
  };

  const handleOnSendaTestEmailGeneratingSchedules = () => {
    if (handleGeneratingSchedulesFormValidation()) {
      setShowGeneratingScheduleModal(true);
    }
  };

  const handleOnUpdateGeneratingSchedules = async () => {
    if (handleGeneratingSchedulesFormValidation()) {
      setIsUpdatingGenerating(true);
      try {
        await EmailTriggerService.updateEmailTemplate(
          generatingSchedulesParameters
        );
        success(
          'Email trigger details for generating schedules successfully updated'
        );
      } catch (error) {
      } finally {
        setIsUpdatingGenerating(false);
      }
    }
  };

  const handleSetSubmittingSchedulesParameters = (e) => {
    setSubmittingSchedulesParameters({
      ...submittingSchedulesParameters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmittingSchedulesFormValidation = () => {
    if (
      !submittingSchedulesParameters.subject ||
      !submittingSchedulesParameters.recipients
    ) {
      setSubmittingSchedulesErrorFields({
        subjectState: !submittingSchedulesParameters.subject,
        subjectErrorMessage: 'Subject is required',
        recipientsState: !submittingSchedulesParameters.recipients,
        recipientsErrorMessage: 'Recipient(s) is required',
      });
      return false;
    }

    setSubmittingSchedulesErrorFields({});
    return true;
  };

  const handleOnSendaTestEmailSubmittingSchedules = () => {
    if (handleSubmittingSchedulesFormValidation()) {
      setShowSubmittingScheduleModal(true);
    }
  };

  const handleOnUpdateSubmittingSchedules = async () => {
    if (handleSubmittingSchedulesFormValidation()) {
      setIsUpdatingSubmitting(true);
      try {
        await EmailTriggerService.updateEmailTemplate(
          submittingSchedulesParameters
        );
        success(
          'Email trigger details for submitting schedules successfully updated'
        );
      } catch (error) {
      } finally {
        setIsUpdatingSubmitting(false);
      }
    }
  };

  const handleGetEmailGeneratingTemplate = async () => {
    try {
      var response = await EmailTriggerService.getEmailTemplate(true);
      setGeneratingSchedulesParameters({
        ...response.data,
        generating: true,
      });
    } catch (error) {}
  };

  const handleGetEmailSubmittingTemplate = async () => {
    try {
      var response = await EmailTriggerService.getEmailTemplate(false);
      setSubmittingSchedulesParameters({
        ...response.data,
        generating: false,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleGetEmailGeneratingTemplate();
    handleGetEmailSubmittingTemplate();
  }, []);

  return (
    <div>
      <Header title="Email Triggers" />

      <AuthenticationComponent />

      <div className={styles.tabHeaders}>
        <div
          className={`${styles.tabHeaderItem} ${selectedTab ===
            'Generating schedules' && styles.tabHeaderItemActive}`}
          onClick={() => setSelectedTab('Generating schedules')}
        >
          Generating schedules
        </div>
        <div
          className={`${styles.tabHeaderItem} ${selectedTab ===
            'Submitting schedules' && styles.tabHeaderItemActive}`}
          onClick={() => setSelectedTab('Submitting schedules')}
        >
          Submitting schedules
        </div>
      </div>
      <div className={styles.tabContents}>
        {selectedTab === 'Generating schedules' ? (
          <GeneratingSchedulesComponent
            parameters={generatingSchedulesParameters}
            errorFields={generatingSchedulesErrorFields}
            onSendaTestEmail={handleOnSendaTestEmailGeneratingSchedules}
            onUpdate={handleOnUpdateGeneratingSchedules}
            onChange={handleSetGeneratingSchedulesParameters}
            isUpdating={isUpdatingGenerating}
          />
        ) : (
          <SubmittingSchedulesComponent
            parameters={submittingSchedulesParameters}
            errorFields={submittingSchedulesErrorFields}
            onSendaTestEmail={handleOnSendaTestEmailSubmittingSchedules}
            onUpdate={handleOnUpdateSubmittingSchedules}
            onChange={handleSetSubmittingSchedulesParameters}
            isUpdating={isUpdatingSubmitting}
          />
        )}
      </div>

      <GeneratingSchedulesSendTestModalComponent
        predefinedRecipient={generatingSchedulesParameters.recipients}
        show={showGeneratingScheduleModal}
        onHide={() => setShowGeneratingScheduleModal(false)}
      />

      <SubmittingSchedulesSendTestModalComponent
        predefinedRecipient={submittingSchedulesParameters.recipients}
        show={showSubmittingScheduleModal}
        onHide={() => setShowSubmittingScheduleModal(false)}
      />
    </div>
  );
};

export default EmailTriggerComponent;
