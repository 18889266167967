import { apiRequestHandler, apiHost } from '.';
import { HttpMethods, APIContexts } from 'enums';
import axios from 'axios';

const apiContext = `${APIContexts.SCHEDULE}/`;

export const GetChannelSchedulesAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetChannelSchedules';
  const endpoint =
    `${apiHost}${apiContext}${api}?ChannelId=${queryParams.channelId}` +
    (queryParams.startDate ? `&Start=${queryParams.startDate}` : '') +
    (queryParams.endDate ? `&End=${queryParams.endDate}` : '') +
    (queryParams.pageNumber ? `&PageNumber=${queryParams.pageNumber}` : '') +
    (queryParams.pageSize ? `&PageSize=${queryParams.pageSize}` : '');

  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const AddChannelScheduleSessionAPI = ({ queryParams, ...rest }) => {
  const api = 'AddChannelScheduleSession';
  const endpoint =
    `${apiHost}${apiContext}${api}?ChannelID=${queryParams.channelId}` +
    (queryParams.startDate ? `&StartDate=${queryParams.startDate}` : '') +
    (queryParams.endDate ? `&EndDate=${queryParams.endDate}` : '');

  apiRequestHandler({
    endpoint,
    method: HttpMethods.POST,
    ...rest,
  });
};

export const GetChannelScheduleSessionAPI = ({ onSuccess, ...rest }) => {
  const api = 'GetChannelScheduleSession';
  const endpoint = `${apiHost}${apiContext}${api}`;

  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ExportChannelScheduleAPI = ({
  queryParams,
  responseType,
  ...rest
}) => {
  const api = 'ExportChannelSchedule';
  const endpoint =
    `${apiHost}${apiContext}${api}?ChannelID=${queryParams.channelId}` +
    (queryParams.startDate ? `&Start=${queryParams.startDate}` : '') +
    (queryParams.endDate ? `&End=${queryParams.endDate}` : '');

  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    responseType: responseType,
    ...rest,
  });
};

export const GetTitleGroupProgramsAPI = ({ queryParams, ...rest }) => {
  const api = 'GetTitleGroupPrograms';
  const { id, channelId, start, end } = queryParams;
  const endpoint = `${apiHost}${apiContext}${api}?searchKey=${id}&channelId=${channelId}&start=${start}&end=${end}`;

  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ImportHistoricalScheduleAPI = (file, start, end, schedules) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append(
    'schedule',
    JSON.stringify({ slots: schedules.channelSchedulePrograms })
  );
  const api = 'ImportHistoricalSchedule';
  const url = `${apiHost}${apiContext}${api}?channelId=${schedules.channelID}&start=${start}&end=${end}&totalSlots=${schedules.channelSchedulePrograms.length}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const SaveHistoricalSchedulesAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'SaveHistoricalSchedule';
  const endpoint = `${apiHost}${apiContext}${api}`;
  apiRequestHandler({
    endpoint,
    payload: queryParams,
    method: HttpMethods.POST,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};
