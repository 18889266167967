/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable indent */
import React from 'react';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';

// Local imports
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import Button from 'shared/components/button/Button';

// API imports
import {
  ExportChannelArchitectureGradeAPI,
  ImportGradeArchitectureAPI,
  GetGradeArchitectureAPI,
} from "api";

// Custom hooks
import useStore from 'store/AccountStore';

// Third-party libraries
import { OpScheduler } from 'op2mise-react-widgets';

// Utility functions
import { formatDateWithMoment } from 'utils';

// Modals
import { ImportComponent } from './modals/ImportComponent';
import ModifyGradingAndMinutage from './modals/ModifyGradingAndMinutage';

// Helper functions
import { getMondayZeroToSixSchedules } from './helper/getMondayZeroToSixSchedules';

// FullCalendar import
import { padStart } from '@fullcalendar/react';

// Styles
import './ChannelArchitecture.css';
import { dayOfWeek } from '@progress/kendo-date-math';

export function CAGrading({
  calculateDates,
  channelInfo,
  endDate,
  handleSaveGradeArchitecture,
  isDirty,
  schedulerProps,
  setIsDirty,
  startDate,
  setGrading
}) {
  const { user } = useStore((state) => state);
  const instance = new Internationalization();
  const [schedules, setSchedules] = React.useState([{}]);
  const [openForm, setOpenForm] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshSchedules, setRefreshSchedules] = React.useState(false);
  const { channelId, channelName } = channelInfo;
  const blockData = React.useRef({});
  const grade = React.useRef(0);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isClockTypeBroadcast, setClockTypeBroadcast] = React.useState(user?.clockType === 'Broadcast');

  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate from date.utils.js
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(
    new Date().toDateString(),
  )} - ${formatDateWithMoment(tomorrow)}`;

  // Date Header Template
  const dateHeaderTemplate = (args) => {
    const day = instance.formatDate(args.date, { skeleton: 'E' });
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className="e-header-day"
          style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
        >
          {day}
        </div>
      </div>
    );
  };

  const eventFields = (args) => ({
    Grade: args.grade,
    Id: args.id,
    ProgramType: args.programType,
    StartTime: calculateDates(args.dayOfWeek, args.week ?? 1, args.startTime, args.endTime, { startDate: startDate, endDate: endDate })['startTime'],
    EndTime: calculateDates(args.dayOfWeek, args.week ?? 1, args.startTime, args.endTime, { startDate, endDate })['endTime'],
    DayOfWeek: args.dayOfWeek,
  })

  const eventTemplate = (args) => {
    const { Grade } = args;
    return (
      <React.Fragment>
        <div className='op-event-subject'>
          <div
            className={`e-schedule-custom-event e-schedule-grading e-schedule-grade-${Grade} e-ca-grading-subject`}
          >
            <div className="e-schedule-custom-event-title e-schedule-grading-title">
              {Grade}
            </div>
          </div>
        </div>
        <div className='op-event-content '>{null}</div>
      </React.Fragment>
    );
  };

  const customFooter = React.useCallback(({ events, schedulerActions }) => {
    return (
      <div style={{ marginTop: '15px', float: 'right' }}>
        <Button
          text="Save"
          style={{ marginLeft: '10px' }}
          onClick={() => {
            handleSaveGradeArchitecture(schedulerActions.EventGetAll());
            schedulerActions.ResetEventHistory();
            setRefreshSchedules(true)
          }}
          disabled={!isDirty}
        />
      </div>
    );
  }, [isDirty]);

  const handleOnExport = () => {
    if (schedules.grading.length) {
      ExportChannelArchitectureGradeAPI({
        queryParams: { channelId },
        onSuccess: (res) => {
          const { organisationName } = user;
          let exportDate = new Date();
          const exportedDateString =
            padStart(exportDate.getDate(), 2).toString() +
            padStart(exportDate.getMonth() + 1, 2).toString() +
            exportDate.getFullYear().toString();
          const fileName = `${organisationName}_op2mise_channel_architecture_grading_export_${channelName}_${exportedDateString}.xlsx`;
          const blob = new Blob([res], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, fileName);
        },
      });
    }
  }

  const handleOnImport = (args) => {
    if (args) {
      blockData.current = {
        EventGetAll: args.schedulerActions.EventGetAll,
        EventImport: args.schedulerActions.EventImport,
        ResetEventHistory: args.schedulerActions.ResetEventHistory,

      }
      setOpenForm('IMPORT');
    }
  }

  const handleOnSlotModified = (args) => {
    blockData.current = {
      ...args.data,
      ProgramType: undefined,
      EventGetAll: args.schedulerActions.EventGetAll,
      EventChange: args.schedulerActions.EventChange,
      ResetEventHistory: args.schedulerActions.ResetEventHistory,
    }
    setIsDialogOpen(true)
  }

  const cleanOutInvalidGrades = (data) => {
    return data.filter((schedule) => schedule.grade);
  }

  const handleUpdateGrade = (newGrade) => {
    blockData.current.EventChange({
      ...blockData.current,
      Grade: newGrade
    })
    const allEvents = blockData.current.EventGetAll()
    setGrading(allEvents);
    setIsDirty(true)
  }

  const modalProps = {
    isOpen: isDialogOpen,
    closeModal: () => setIsDialogOpen(false),
    handleUpdate: handleUpdateGrade,
    blockData: blockData.current,
    type: 'grade',
  }

  React.useEffect(() => {
    // Change between Standard and Broadcast clocktype
    setClockTypeBroadcast(user?.clockType === 'Broadcast');
  }, [user]);

  React.useEffect(() => {
    setIsDialogOpen(false);
    blockData.current = {};
    grade.current = 0;
  }, []);

  React.useEffect(() => {
    if (channelId || refreshSchedules) {
      GetGradeArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          const validGrades = cleanOutInvalidGrades(response);
          setSchedules({
            grading: getMondayZeroToSixSchedules(
              [...validGrades],
              { startDate, endDate },
              isClockTypeBroadcast)
          });
          setRefreshSchedules(false);
        },
        setLoader: setIsLoading,
      });
    }
  }, [channelId]);

  return (
    <>
      {' '}
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm(false)}
          setIsDirty={setIsDirty}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            schedules: schedules.grading,
            period: { startTime: startDate, endTime: endDate },
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="gradeImportTemplate"
          importResultColumns={[{
            field: 'dayOfWeek',
            headerText: 'Day of Week',
          }, {
            field: 'startTime',
            headerText: 'Start Time',
          }, {
            field: 'endTime',
            headerText: 'End Time',
          }, {
            field: 'grade',
            headerText: 'Grade',
          }]}
          importResultFileTitle="grade_import_result"
          handleOnImportApi={ImportGradeArchitectureAPI}
          dataExists='gradeMinutageArchitectureExist'
          dataModel='gradeMinutageArchitectureModel'
          fileError='gradeMinutageArchitectureError'
          setSchedules={(data) => {
            if (data.length) {
              let importedData = getMondayZeroToSixSchedules(
                data.map((o) => ({ id: guid(), ...o })),
                { startDate, endDate },
                isClockTypeBroadcast)
              blockData.current.EventImport(importedData)
              setGrading(blockData.current.EventGetAll())
              setIsDirty(true)
              setIsLoading(false);
            }
          }}
        />
      )}
      {isDialogOpen && (
        <ModifyGradingAndMinutage
          {...modalProps}
        />
      )}
      {(channelId === 0) && !channelName
        ? null
        : (<OpScheduler
          {...schedulerProps}
          actionButtonPanel={(events, schedulerActions) => customFooter(events, schedulerActions)}
          addButton={false}
          dateHeaderTemplate={dateHeaderTemplate}
          dataSource={schedules}
          eventFields={eventFields}
          eventLookupList={'grading'}
          eventTemplate={eventTemplate}
          heightBuffer={230}
          loading={isLoading}
          onExport={handleOnExport}
          onImport={handleOnImport}
          onSlotModified={handleOnSlotModified}
          schedule={{ start: startDate, end: endDate }}
          settings={['SLOT DURATION', 'SLOT INTERVAL']}
          suppressOverlappingSchedules={true}
          suppressQuickInfo={true}
          suppressTooltip={true}
          timeoutDelay={800}
        />)}
    </>
  );
}

export default CAGrading;