import React, { memo } from 'react'
import {
    TimePickerComponent,
    Inject,
    MaskedDateTime
} from '@syncfusion/ej2-react-calendars';
import './OpTimeInput.css';
import { Settings } from './constants';

const OpTimeInput = memo((props) => {
    const { widthOptions } = Settings;
    const {
        label,
        value,
        onChange,
        enabled = true,
        min, // default of 00:00, typeof Date
        max, // default of 00:00, typeof Date
        maskPlaceholder = { hour: 'HH', minute: 'mm' },
        format = 'HH:mm',
        interval = 60,
        readonly = false,
        width = 'fit', // fit: 100, full: null
        textAlign = 'left', // left, center, right
        style,
        direction
    } = props;

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: direction === 'row' ? 'row' : 'column',
            width: '100%',
        }}>
            {label && <p style={{
                fontSize: '14px',
                color: '#6D6D73',
                marginBottom: '5px',
                marginRight: '10px',
                whiteSpace: 'nowrap',
            }}>{label}</p>}
            <TimePickerComponent
                cssClass="op-timepicker"
                value={value}
                step={interval}
                format={format}
                min={min}
                max={max}
                onChange={(e) => onChange(e.target.value)}
                enabled={enabled}
                showClearButton={false}
                readonly={readonly}
                allowEdit={true}
                width={widthOptions[width]}
                placeholder={format}
                enableMask={true} // only works when placeholder is not set
                maskPlaceholder={maskPlaceholder}
                style={{ textAlign: textAlign, width: '100%', ...style }}
            >
                <Inject services={[MaskedDateTime]} />
            </TimePickerComponent>
        </div>
    )
})

export default OpTimeInput