import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../profile/profile.module.css';
import { chevLeftCircleIcon } from 'assets';

const ChevronComponent = ({
    toggleSidebar,
  }) => {
  const [sidebarState, setSidebarState] = useState(false);

  const getBrowserWidth = () => {
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  };

  const toggleSidebarState = () => {
    setSidebarState(!sidebarState);
    toggleSidebar(!sidebarState);
  };

  const onToggleSidebarByKeyboard = (e) => {
    if (e.key === 'Enter') toggleSidebarState();
  };

  useEffect(() => {
    const handleResize = () => {
      if (getBrowserWidth() < 800) {
        setSidebarState(true);
      } else {
        setSidebarState(false);
      }
    };
    window.addEventListener('resize', handleResize);

  });
  useEffect(() => {
    if (getBrowserWidth() < 800) {
      setSidebarState(true);
    } else {
      setSidebarState(false);
    }
  }, []);

  return (
    <>
      <div
        className="position-relative w-100"
        style={{ marginBottom: '33px' }}
      >
        <img
          className={`
          ${styles.chevLeftCircleIcon}
          ${sidebarState ? styles.chevLeftRotateIcon : ''}
        `}
          style={{
            marginRight: sidebarState
                ? '-22px'
                : '-11px',
            marginTop: sidebarState
              ? '79px'
              : '79px',
            zIndex: 1,
          }}
          src={chevLeftCircleIcon}
          alt="hamburgerIcon"
          onClick={toggleSidebarState}
          onKeyDown={onToggleSidebarByKeyboard}
          role="presentation"
          title={sidebarState ? 'Expand Menu' : 'Collapse Menu'}
        />
      </div>
    </>
  );
};
ChevronComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  hideProfileLink: PropTypes.func.isRequired,
  callbackUserLogout: PropTypes.func.isRequired,
};
ChevronComponent.displayName = 'ChevronComponent';

export default ChevronComponent;

