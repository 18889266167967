import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'shared/components/button/Button';
import { Internationalization } from '@syncfusion/ej2-base';
import { formatDateWithMoment } from 'utils';
import { Input } from '@progress/kendo-react-inputs';
import Switch from 'shared/components/switch/Switch';
import style from './../architecture.module.css';
import Select from 'shared/components/select/Select';
import {
  ActionTypes,
  Layouts,
  BlockTypes,
  defaultModalState,
  daysAbbreviation,
} from '../ChannelArchitecture.d';
import { cAModalReducer } from '../reducers/schedules.reducer';
import { guid } from '@progress/kendo-react-common';
import ReccurencePatternComponent from 'components/schedule/react-scheduler/forms/ReccurencePatternComponent';
import OpTimeInput from 'op2mise-react-widgets/OpTimeInput/OpTimeInput';
import {
  getAllLinkedBlocks,
  updateMultipleData,
  getBlockName,
  getNewBlockReference
} from '../utils/architecture.utils';
import { getMondayOfTheWeek, addDaysToDate } from 'utils';
import moment from 'moment';
import { getMondayZeroToSixSchedules } from '../helper/getMondayZeroToSixSchedules';
import { reconstructBlockName } from '../utils/architecture.utils';
import { dayOfWeek } from '@progress/kendo-date-math';
import { SingleBed } from '@mui/icons-material';

const ModifyBlock = ({
  allEvents,
  setIsDialogOpen,
  blockData,
  numberOfWeeks,
  modalType,
  titleGroupList,
  seriesList,
  referenceList,
  isBroadcast,
  setIsDirty,
  setAllEvents,
  findRelatedBlocks,
  getSeriesInfo,
  getTitleGroupInfo,
  setModalType,
  updateBlockReferences,
}) => {
  const instance = new Internationalization();
  const [recurencePattern, setRecurencePattern] = React.useState({
    id: 2,
    name: 'Daily',
  });
  const [modalState, dispatchModalState] = React.useReducer(
    cAModalReducer,
    defaultModalState
  );
  const [references, setReferences] = React.useState([]);
  const [schedules, setSchedules] = React.useState(allEvents)
  // Input Component for Modify Block Dialog
  const InputComponent = ({ label, input, gap }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: gap ?? 20,
      }}
    >
      {label && <p>{label}</p>}
      {input}
    </div>
  );

  const daysOfWeek = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 7, name: 'Sunday' },
  ];

  // Get the selected day of the week
  const getSelectedDayOfWeek = (date) => {
    const startDay = modalType == ActionTypes.CREATE ? getMondayOfTheWeek(new Date()) : date;
    // Get the full name of the day of the week
    const day = instance.formatDate(new Date(startDay), {
      skeleton: 'E',
      type: 'date',
    });
    return daysOfWeek.find((dayOfWeek) => dayOfWeek.name.includes(day));
  };

  const displayLayoutInput = (input) => {
    const layout = ['Sequential', 'Shuffle'];
    if (!input && input?.length) return 'Please select a preferred layout';

    return layout.includes(input) ? input : 'Please select a preferred layout';
  }

  React.useEffect(() => {
    if (blockData?.EventGetAll && blockData?.EventGetAll()?.length
    ) {
      const constructData = () => {
        let transformedData = blockData.EventGetAll().map((schedule) => {
          return {
            availableDuration: schedule?.AvailableDuration || 0,
            blockName: getBlockName(schedule.DayOfWeek, schedule.StartTime),
            blockReference: schedule?.BlockReference,
            dayOfWeek: schedule.DayOfWeek,
            endTime: moment(schedule.EndTime, "HH:mm:ss").format("HH:mm:ss"),
            genre: schedule?.Genre || null,
            layout: schedule?.Layout,
            link: schedule?.IsLinked ? schedule.IsLinked : false,
            maxCount: schedule?.MaxCount || 0,
            seasonID: schedule?.SeasonID || null,
            seriesID: schedule?.SeriesID || null,
            seriesName: schedule?.SeriesName || null,
            sequential: false,
            startTime: moment(schedule.StartTime, "HH:mm:ss").format("HH:mm:ss"),
            titleGroupID: schedule?.TitleGroupID || null,
            titleGroupName: schedule.TitleGroupName || null,
            type: schedule?.Type || 'Movies',
            week: schedule.Week,
            id: schedule.Id,
          }
        })
        return transformedData.filter((o) => o.week === 1);
      }

      setSchedules({ programmes: constructData() })
    } else {
      setSchedules(allEvents)
    }
  }, [blockData, allEvents])

  // Set the block data to be modified for the Modify Block Dialog
  React.useEffect(() => {
    if (blockData && Object.keys(blockData).length !== 0) {
      const {
        State,
        StartTime,
        EndTime,
        Subject,
        IsSequential,
        IsLinked,
        BlockReference,
        TitleGroupName,
        BlockName,
        Genre,
        Week,
        DayOfWeek,
        MaxCount,
        AvailableDuration,
        Layout,
        SeriesName,
        Type,
        SeasonID,
        SeriesID,
        TitleGroupID,
      } = blockData;
      const selectedTitleGroup = titleGroupList.find(
        (o) => o.name === TitleGroupName
      );

      const selectedLinkReference = referenceList.find(
        (o) => o.blockName === BlockReference && o.name.includes(Type) || (SeriesID === o.series?.seriesID || TitleGroupID === o.titleGroup?.id)
      );
      const selectedSeries = seriesList.find((o) => {
        if (Type === "Repeat" && selectedLinkReference) {
          const referenceBlock = schedules.programmes.find((e) => selectedLinkReference.blockName === e.blockName)
          if (o.name === referenceBlock.seriesName) {
            return o;
          }
        }

        if (o.name === SeriesName) return o;
      });
      const selectedSeason =
        selectedSeries && SeasonID
          ? selectedSeries.seasons.find((o) => o.id === +SeasonID)
          : null;
      const isDisabled = (selectedSeries || selectedTitleGroup) ? true : false;
      dispatchModalState({
        action: 'changeValue',
        newValues: {
          type: Type,
          weekNumber: 1,
          dayOfWeek: getSelectedDayOfWeek(StartTime),
          startTime: StartTime,
          endTime: EndTime,
          hasLink: IsLinked,
          sequential: IsSequential,
          series: selectedSeries,
          seasons: selectedSeason,
          genre: Genre,
          layout: displayLayoutInput(Layout),
          programMaxCount: MaxCount,
          programAvailableDuration: AvailableDuration,
          titleGroup: selectedTitleGroup,
          linkReference: selectedLinkReference,
          disableSeries: Type !== 'Series' ? true : false,
          disableSeason: Type !== 'Series' ? true : false,
          disableTitleGroup: Type !== 'TitleGroup' ? true : false,
          disableType: isDisabled,
          disableLinkReference: isDisabled,
          disableLinkToggle: isDisabled,
          disableLayout: isDisabled,
          disableEndTime: isDisabled,
          disableStartTime: isDisabled,
          disableDayOfWeek: isDisabled,
        },
      });

      if (modalType === ActionTypes.REPEAT) {
        dispatchModalState({
          action: 'changeValue',
          newValues: {
            type: 'Repeat',
            hasLink: true,
            linkReference: referenceList.find(
              (o) => o.blockName === BlockName),
            disableType: true,
            disableSeason: true,
            disableSeries: true,
            disableTitleGroup: true,
            disableLinkReference: true,
            disableLinkToggle: true,
            disableLayout: true,
            disableEndTime: false,
            disableStartTime: false,
            startTime: modalType === ActionTypes.REPEAT ? null : StartTime,
            endTime: modalType === ActionTypes.REPEAT ? null : EndTime,
          },
        });
      }
    }
  }, []);

  // Get max number of weeks to populate the week number select
  const getWeeks = () => {
    let weeks = [];
    for (let i = 1; i <= 52; i++) {
      weeks.push(i);
    }
    return weeks.map((week) => ({ id: week, name: week }));
  };

  /**
   * Builds a daily recurrence pattern for events.
   *
   * @param {number} day - The day of the week (1 = Monday, 2 = Tuesday, ..., 7 = Sunday).
   * @param {number} weeks - The recurrence week (1 = Week 1, 2 = Week 2, ..., 0 = Every Week).
   * @param {Object} block - The event block that will be generated based on the recurrence pattern.
   * @param {number} intervalId - The ID of the interval for which the blocks will be created.
   *                            - 1: Every {Monday | Tuesday | Wednesday | Thursday | Friday | Saturday | Sunday}
   *                            - 2: Every Weekday
   *                            - 3: Every Weekend
   */
  const buildDailyRecurrence = (day, weeks, block, intervalId) => {
    const generatedSchedules = [];
    const linkReferenceName = (dayNo, startTime) => `${dayNo}${moment(startTime, "HH:mm").format("HH:mm")}`
    let daysToAdd = [];
    let prevBlock = block.blockReference ? block.blockReference : '';

    switch (intervalId) {
      case 1: //every selected day
        daysToAdd = [day];
        break;
      case 2: //every weekdays
        daysToAdd = [1, 2, 3, 4, 5];
        break;
      case 3: //every weekends
        daysToAdd = [6, 7];
        break;
    }

    if (!weeks) {
      // Block/s will be generated every week for {numberOfWeeks} weeks
      [...Array(numberOfWeeks).keys()].forEach((weekIteration) => {
        daysToAdd.map((value) => {
          const linkReference = linkReferenceName(daysAbbreviation[value - 1], block.startTime);
          generatedSchedules.push({
            ...block,
            id: guid(),
            week: weekIteration + 1,
            dayOfWeek: value,
            blockName: linkReference,
            link: prevBlock != '' ? true : block.link,
            blockReference: prevBlock,
          });
          prevBlock = linkReference;
        });
      })
    } else {
      // Block/s will be generated for week {weeks}
      daysToAdd.map((value) => {
        const linkReference = linkReferenceName(daysAbbreviation[value - 1], block.startTime);
        generatedSchedules.push({
          ...block,
          id: guid(),
          week: weeks,
          dayOfWeek: value,
          blockName: linkReference,
          link: prevBlock != '' ? true : block.link,
          blockReference: prevBlock,
        });
        prevBlock = linkReference;
      });
    }
    return generatedSchedules;
  };
  const buildWeeklyRecurrence = (days, weekStart, weekInterval, block) => {
    const toInsert = [];
    let prevBlock = block.blockReference ? block.blockReference : '';
    if (modalType === ActionTypes.CREATE) {
      for (var i = weekStart; i <= numberOfWeeks; i += parseInt(weekInterval)) {
        days.map((value) => {
          toInsert.push({
            ...block,
            id: guid(),
            week: i,
            dayOfWeek: value,
            blockName: daysAbbreviation[value - 1] + moment(block.startTime, "HH:mm").format("HH:mm"),
            link: prevBlock.length !== 0 ? true : block.link,
            blockReference: prevBlock,
          });
          prevBlock = daysAbbreviation[value - 1] + moment(block.startTime, "HH:mm").format("HH:mm");
        });
      }
    } else {
      prevBlock = block.blockReference
      const linkedProgram = schedules.programmes.find(
        (o) => reconstructBlockName(o.blockName) === reconstructBlockName(block.blockReference));

      const allLinkedBlocks = schedules.programmes.filter((event) => {
        if (reconstructBlockName(event.blockName) === block.blockReference) {
          return event;
        }
        if (reconstructBlockName(event.blockReference)
          && (reconstructBlockName(event.blockReference) === prevBlock)
          && event.week === 1 && event.startTime === linkedProgram.startTime) {
          prevBlock = reconstructBlockName(event.blockName);
          return event;
        }
      })
      for (var i = weekStart; i <= numberOfWeeks; i += parseInt(weekInterval)) {
        days.map((value, index) => {

          if (allLinkedBlocks[index]) {
            toInsert.push({
              ...block,
              id: guid(),
              week: 1,
              dayOfWeek: value,
              blockName: daysAbbreviation[value - 1] + moment(block.startTime, "HH:mm").format("HH:mm"),
              link: prevBlock != '' ? true : block.link,
              blockReference: allLinkedBlocks[index].blockName,
            });
          }

        })
      }
    }
    return toInsert;
  };

  const canAddDay = (linkedProgram, temp) => {
    const canAddWeek = linkedProgram.week < numberOfWeeks;
    const canAddDay = linkedProgram.dayOfWeek == 7 ? canAddWeek : true;
    return canAddDay;
  };

  const hasOverlapping = (tempList, compareList) => {
    let isOverlap = false;
    const allBlocks = compareList;
    tempList.map((value) => {
      const sameDayBlocks = allBlocks.filter(
        (o) =>
          o.dayOfWeek === value.dayOfWeek &&
          o.week === value.week &&
          o.id != value.id
      );
      const endTimeValue =
        value.endTime === '00:00:00' && value.startTime === '00:00:00'
          ? '24:00:00'
          : value.endTime;
      if (!isOverlap) {
        const overlapSameDay =
          sameDayBlocks.length == 0
            ? false
            : sameDayBlocks.some(
              (o) =>
                o.startTime <= value.startTime && o.endTime > value.startTime
            ) ||
            sameDayBlocks.some(
              (o) =>
                o.startTime >= value.startTime && o.startTime < endTimeValue
            ) ||
            sameDayBlocks.some(
              (o) =>
                o.startTime >= value.startTime &&
                value.startTime > endTimeValue
            )
            ||
            sameDayBlocks.some(
              (o) =>
                o.startTime === value.startTime &&
                o.endTime === value.endTime
            )
        if (overlapSameDay) {
          isOverlap = true;
        }

        //check before day if not first day
        if (!isOverlap && !(value.dayOfWeek === 1 && value.week === 1)) {
          const dayBefore =
            parseInt(value.dayOfWeek) === 1 ? 7 : parseInt(value.dayOfWeek) - 1;
          const weekBefore =
            dayBefore === 7 ? parseInt(value.week) - 1 : value.week;
          const beforeDayBlocks = allBlocks
            .filter((o) => o.dayOfWeek === dayBefore && o.week === weekBefore)
            .sort((a, b) =>
              a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0
            );
          if (beforeDayBlocks.length > 0) {
            //get latest block
            const overlapDayBefore = beforeDayBlocks.some(
              (o) =>
                o.endTime > value.startTime &&
                o.endTime > value.startTime &&
                o.startTime > o.endTime
            );
            if (overlapDayBefore) isOverlap = true;
          }
        }

        //check after day if not last day
        const overnightBlock = value.startTime > endTimeValue;
        if (
          !(value.dayOfWeek === 7 && value.week === numberOfWeeks) &&
          overnightBlock
        ) {
          const dayAfter =
            parseInt(value.dayOfWeek) === 7 ? 1 : parseInt(value.dayOfWeek) + 1;
          const weekAfter =
            dayAfter === 1 ? parseInt(value.week) + 1 : value.week;
          const afterDayBlocks = allBlocks
            .filter((o) => o.dayOfWeek === dayAfter && o.week === weekAfter)
            .sort((a, b) =>
              a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0
            );
          if (afterDayBlocks.length > 0) {
            //get first block
            const overlapDayAfter = afterDayBlocks.some(
              (o) => o.startTime < endTimeValue && o.startTime < o.endTime
            );
            if (overlapDayAfter) isOverlap = true;
          }
        }

        const allDayEvent = value.startTime === value.endTime
        if (allDayEvent && modalType === ActionTypes.CREATE) {
          if (blockData.EventConflictValidator(value)) {
            isOverlap = true;
          }
        }
      }
    });
    return isOverlap;
  };

  const handleErrorMessage = (message) => {
    dispatchModalState({
      action: 'changeValue',
      newValues: {
        errorMessage: message,
      },
    });
  };

  const getLinkReferenceInfo = (data) => {
    return schedules.programmes.find(
      (o) => o.id === data.id
    );
  }

  // Handle the change of the start time for After Link Recurrence Pattern
  const handleStartTimeChange = (recurencePattern) => {
    if (modalState.hasLink && modalState.linkReference) {
      if (Object.keys(recurencePattern).length === 0) {
        const linkedProgram = schedules.programmes.find(
          (o) => reconstructBlockName(o.blockName) === reconstructBlockName(modalState.linkReference.blockName)
        );
        changeForm('startTime', moment(linkedProgram.endTime, "HH:mm:ss").toDate())
      }
    }
  }

  const handleTitleChange = (linkReference) => {
    if (linkReference && modalState.type === 'Repeat') {
      // changeForm('series', args);
      const info = getLinkReferenceInfo(linkReference);
      const seriesInfo = seriesList.find((o) => o.name === info?.seriesName)
      const titleGroupInfo = titleGroupList.find((o) => o.name === info?.titleGroupName)
      if (seriesInfo) {
        if (info.seasonID) {
          const seasonInfo = seriesInfo.seasons.find((o) => o.id === info.seasonID)
          changeForm('seasons', seasonInfo);
        }
        changeForm('series', seriesInfo)
        changeForm('titleGroup', null)
      }
      if (titleGroupInfo) {
        changeForm('titleGroup', titleGroupInfo)
        changeForm('series', null)
        changeForm('seasons', null);
      }
    }
  }

  // Update all linked reference blocks to the new block
  const updatedLinkedBlocks = (updatedBlock, originalBlockData) => {
    // Get all blocks that is a block reference to the selected block
    const directLinks = schedules.programmes.filter((block) => {
      if (updatedBlock.id !== block.id && originalBlockData.BlockName === block.blockReference) {
        return block;
      }
    })

    // Update all direct linked blocks based on the updated block
    const updatedDirectLinkedBlocks = directLinks.map((block) => {
      if (block.type === 'Repeat') {
        return {
          ...block,
          blockReference: updatedBlock.blockName,
          seriesID: updatedBlock.seriesID,
          seriesName: updatedBlock.seriesName,
          seasonID: updatedBlock.seasonID,
          titleGroupID: updatedBlock.titleGroupID,
          titleGroupName: updatedBlock.titleGroupName,
        }
      } else {
        return {
          ...block,
          blockReference: updatedBlock.blockName,
        }
      }
    })
    return updatedDirectLinkedBlocks;
  }

  const handleModifyBlock = (props) => {
    if (!props.StartTime || !props.EndTime) {
      handleErrorMessage('Start and end time is required.');
      return;
    } else
      if (!modalState.linkReference && modalState.hasLink) {
        handleErrorMessage('Link reference is required.');
        return;
      } else if (modalState.type === 'Repeat' && !modalState.hasLink) {
        handleErrorMessage('Link reference is required.');
        return;
      }
    let isValid = true;
    const selectedReference = referenceList.find((o) => o.blockName === modalState.linkReference?.blockName);
    const linkedProgram = selectedReference ? getLinkReferenceInfo(selectedReference) : [];

    const period = {
      startDate: getMondayOfTheWeek(props.StartTime),
      endDate: addDaysToDate(getMondayOfTheWeek(props.EndTime), 6),
    };
    // Change when backend is ready
    const edited = !schedules
      ? {}
      : schedules.programmes.find((o) => o.id === props.Id);

    const selectedTitleGroup = titleGroupList.find(
      (o) => o.name === linkedProgram?.titleGroupName
    ) ?? null;
    const temp = {
      ...edited,
      id: blockData.Id,
      genre: modalState.genre,
      maxCount: modalState.programMaxCount,
      availableDuration: modalState.programAvailableDuration,
      week: props.Week,
      dayOfWeek: props.DayOfWeek.id,
      startTime: formatDateWithMoment(props.StartTime, 'HH:mm:ss'),
      endTime: formatDateWithMoment(props.EndTime, 'HH:mm:ss'),
      layout: modalState.layout,
      link: modalState.hasLink ? true : false,
      sequential: modalState.sequential,
      type: props.type,
      series: props.type === 'Repeat' ? linkedProgram?.seriesID : modalState.series,
      seasonID: props.SeasonID,
      seriesID: props.SeriesID,
      seriesName: props.type === 'Repeat' ? linkedProgram.seriesName : modalState.series && modalState.series.name,
      titleGroupName: props.type === 'Repeat' ? linkedProgram.titleGroupName : props.TitleGroupName,
      titleGroupID: props.type === 'Repeat' ? selectedTitleGroup?.id : props.TitleGroupID,
      blockReference:
        modalState.linkReference && modalState.linkReference.blockName,
      blockName:
        daysAbbreviation[props.DayOfWeek.id - 1] +
        formatDateWithMoment(props.StartTime, 'HH:mm'),
    };
    if (modalType === ActionTypes.UPDATE) {
      //check overlap
      if (!hasOverlapping([temp], schedules.programmes)) {
        let singleData = {
          ...temp,
          blockName: getBlockName(temp.dayOfWeek, temp.startTime),
          blockReference: temp.blockReference,
          link: temp.blockReference ? true : false,
        }
        let found = [];
        if (singleData.type === 'Series' || singleData.type === 'Repeat') {
          const seriesInfo = getSeriesInfo(blockData.SeriesName, blockData.SeasonID);
          if (seriesInfo) {
            found = findRelatedBlocks(blockData, seriesInfo, schedules.programmes)
            // update all blocks to remove link reference for found blocks to be deleted
            if (found.length) {
              const updated = found.map((block) => ({
                ...singleData,
                type: block.type,
                id: block.id,
                dayOfWeek: block.dayOfWeek,
                startTime: block.startTime,
                endTime: block.endTime,
                blockName: block.blockName,
                blockReference: block.blockReference,
                link: block.blockReference ? true : false,
              }))
              blockData.EventChange([singleData, ...updated])
            }
          } else {
            blockData.EventChange([...updatedLinkedBlocks(singleData, blockData), singleData])
          }
        } else if (singleData.type === 'TitleGroup' || singleData.type === 'Repeat') {
          const titleGroupInfo = getTitleGroupInfo(blockData.TitleGroupName);
          if (titleGroupInfo) {
            found = findRelatedBlocks(blockData, titleGroupInfo, schedules.programmes)
            if (found.length) {
              const updated = found.map((block) => ({
                ...singleData,
                type: block.type,
                id: block.id,
                dayOfWeek: block.dayOfWeek,
                startTime: block.startTime,
                endTime: block.endTime,
                blockName: block.blockName,
                blockReference: block.blockReference,
                link: block.blockReference ? true : false,
              }))

              blockData.EventChange([singleData, ...updated])
            }
          } else {
            blockData.EventChange([...updatedLinkedBlocks(singleData, blockData), singleData])
          }
        } else {
          blockData.EventChange([...updatedLinkedBlocks(singleData, blockData), singleData])
        }
        if (isBroadcast) {
          let week2Clone = blockData?.CloneId && blockData.EventGetAll().find((o) => blockData.CloneId === o.Id);
          if (week2Clone) blockData.EventChange({
            ...temp,
            id: week2Clone.Id,
            week: week2Clone.Week,
          })
        }
      } else {
        handleErrorMessage(
          'Unable to update block. A new block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your new block to avoid conflicts.'
        );
        isValid = false;
      }
    } else {
      if (Object.keys(recurencePattern).length === 0) {
        // After Link
        const linkedProgram = schedules.programmes.find(
          (o) => reconstructBlockName(o.blockName) === reconstructBlockName(temp.blockReference)
        );
        if (!linkedProgram) {
          handleErrorMessage('Link reference is required.');
          isValid = false;
        } else {
          const isAddDay = linkedProgram.endTime === '00:00:00';
          const validLink = isAddDay ? canAddDay(linkedProgram, temp) : true;
          if (validLink) {
            const day = isAddDay
              ? parseInt(linkedProgram.dayOfWeek) == 7
                ? 1
                : parseInt(linkedProgram.dayOfWeek) + 1
              : parseInt(linkedProgram.dayOfWeek);
            const week =
              isAddDay && parseInt(linkedProgram.dayOfWeek) == 7
                ? parseInt(linkedProgram.week) + 1
                : linkedProgram.week;
            let toInsert = {
              ...temp,
              id: guid(),
              week: week,
              dayOfWeek: day,
              startTime: linkedProgram.endTime,
              blockName: daysAbbreviation[day - 1] + moment(temp.startTime, "HH:mm:ss").format("HH:mm"),
            };
            if (!hasOverlapping([toInsert], schedules.programmes)) {
              //check overlap
              toInsert = getMondayZeroToSixSchedules(
                [toInsert],
                period,
                isBroadcast
              )
              blockData.EventCreate(toInsert)
            } else {
              handleErrorMessage(
                'Unable to insert new block. A new block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your new block to avoid conflicts.'
              );
              isValid = false;
            }
          } else {
            handleErrorMessage('After link reference is out of bounds');
            isValid = false;
          }
        }
      } else {
        const {
          intervalId,
          intervalValue,
          reccurencePatternId,
          week,
          everyWeekInterval,
          days,
        } = recurencePattern;
        switch (reccurencePatternId) {
          // Daily pattern
          case 2:
            let dailyInsertList = buildDailyRecurrence(
              intervalValue,
              week.id,
              temp,
              intervalId
            );
            if (
              !hasOverlapping(dailyInsertList, dailyInsertList) &&
              !hasOverlapping(dailyInsertList, schedules.programmes)
            ) {
              dailyInsertList = getMondayZeroToSixSchedules(
                dailyInsertList,
                period,
                isBroadcast)

              blockData.EventCreate(dailyInsertList)
            } else {
              handleErrorMessage(
                'Unable to insert new block. A new block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your new block to avoid conflicts.'
              );
              isValid = false;
            }
            break;

          case 3:
            //weekly pattern
            let weeklyInsertList = buildWeeklyRecurrence(
              days,
              props.Week,
              everyWeekInterval,
              temp
            );
            if (
              !hasOverlapping(weeklyInsertList, weeklyInsertList) &&
              !hasOverlapping(weeklyInsertList, schedules.programmes) && days.length > 0
            ) {
              weeklyInsertList = getMondayZeroToSixSchedules(
                weeklyInsertList,
                period,
                isBroadcast)

              blockData.EventCreate(weeklyInsertList)
            } else {
              if (days.length === 0) {
                handleErrorMessage('Please select the day(s) of the week');
              } else
                handleErrorMessage(
                  'Unable to insert new block. A new block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your new block to avoid conflicts.'
                );
              isValid = false;
            }
            break;
        }
      }
    }
    if (!modalState.errorMessage)
      setIsDirty(true);

    setAllEvents(blockData.EventGetAll())
    isValid && setIsDialogOpen(false);
  };

  const changeType = (args) => {
    dispatchModalState({
      action: 'changeType',
      type: args.name,
    });
  };

  const changeForm = (attribute, value) => {
    dispatchModalState({
      action: 'changeValue',
      newValues: {
        [attribute]: value,
      },
    });
  };

  const changeLinkRefenrece = (args) => {

    dispatchModalState({
      action: 'changeValue',
      newValues: {
        linkReference: args,
        disableStartTime: Object.keys(recurencePattern).length === 0,
      },
    });
  };

  const handleToggleLink = (value) => {
    dispatchModalState({
      action: 'changeValue',
      newValues: {
        hasLink: value,
        disableStartTime: false,
        linkReference: null,
      },
    });
  };
  React.useEffect(() => {
    handleReferenceList()
  }, [modalState.type, modalState.series, modalState.seasons, modalState.titleGroup])

  const handleReferenceList = () => {
    const list = referenceList.filter((o) => {
      switch (modalState.type) {
        case 'Series':
          if (o.name.includes('Series')) {
            if (modalState.seasons && modalState.seasons?.name !== 'Please select a preferred series') {
              if (modalState.seasons.name === o.series?.seasonName && modalState.series.name === o.series?.seriesName) {
                return o;
              }
            } else if (modalState.series) {
              if (modalState.series.name === o.series?.seriesName) {
                return o;
              }
            } else {
              return o;
            }
          }
          break;
        case 'TitleGroup':
          if (o.name.includes('TitleGroup')) {
            if (modalState.titleGroup) {
              if (modalState.titleGroup.name === o.titleGroup?.name) {
                return o;
              }
            } else {
              return o;
            }
          }
          break;
        case 'Movies':
          if (o.name.includes('Movies')) {
            return o;
          }
          break;
        case 'Placeholder':
          if (o.name.includes('Placeholder')) {
            return o;
          }
          break;
        default:
          return o;
      }
    })
    setReferences(list)
  }

  const modalHeaderTitle = () => {
    switch (modalType) {
      case 'CREATE':
        return 'New';
      case 'UPDATE':
        return 'Modify';
      case 'REPEAT':
        return 'Repeat';
    }
  }

  return (
    <Modal
      show={true}
      onHide={() => setIsDialogOpen(false)}
      style={{ '--bs-modal-width': '1030px' }}
    >
      <Modal.Body>
        <div style={{ padding: '30px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 7,
              fontSize: '20px',
            }}
          >
            {modalHeaderTitle()} Block
            {(modalType == ActionTypes.UPDATE
              || modalType == ActionTypes.MULTIPLEUPDATE) && <span>|</span>}
            <div style={{ color: '#6D6D73' }}>{blockData.BlockName}</div>
          </div>
          <div
            style={{
              display: 'flex',
              padding: '25px 0',
              gap: 65,
              fontSize: '14px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                flex: 1,
                width: '50%',
              }}
            >
              <InputComponent
                label="Type"
                input={
                  <Select
                    text={modalState.type}
                    value={modalState.type}
                    list={Object.keys(recurencePattern).length !== 0 ? BlockTypes : BlockTypes.filter((o) => o.name !== 'Repeat')}
                    onSelect={(args) => {
                      changeType(args);
                      if (modalType === ActionTypes.CREATE || !modalState.disableLinkToggle)
                        changeLinkRefenrece(null)
                    }}
                    width="250px"
                    disabled={modalType === ActionTypes.REPEAT || modalState.disableType}
                  />
                }
              />

              <InputComponent
                label="Genre"
                input={
                  <Input
                    className={`${style.modalInput}`}
                    type="text"
                    defaultValue={modalState.genre}
                    placeholder="Enter a preferred genre"
                    onBlur={(e) => changeForm('genre', e.target.value)}
                    style={{ width: '252px' }}
                    disabled={modalState.disabledGenre}
                  />
                }
              />

              <InputComponent
                label="Program MAX Count"
                input={
                  <input
                    type="number"
                    defaultValue={modalState.programMaxCount}
                    min={1}
                    max={9999}
                    onBlur={(e) =>
                      changeForm('programMaxCount', e.target.value)
                    }
                    style={{ width: '190px' }}
                    className={`${style.modalInput} ${style.inputWrapper}`}
                    disabled={modalState.disableMaxCount}
                    placeholder="0"
                  />
                }
              />

              <InputComponent
                label="Program Available Duration"
                input={
                  <input
                    type="number"
                    defaultValue={modalState.programAvailableDuration}
                    min={1}
                    max={9999}
                    onBlur={(e) =>
                      changeForm('programAvailableDuration', e.target.value)
                    }
                    style={{ width: '190px' }}
                    className={`${style.modalInput} ${style.inputWrapper}`}
                    disabled={modalState.disableAvailableDuration}
                    placeholder=":MM"
                  />
                }
              />

              <InputComponent
                label="Series"
                input={
                  <Select
                    text={
                      modalState.series
                        ? modalState.series.name
                        : 'Please select a preferred series'
                    }
                    value={modalState.series}
                    list={seriesList}
                    onSelect={(args) => {
                      if (modalState.series?.name !== args?.name) {
                        changeForm('seasons', { name: 'Please select a preferred series' })
                      }
                      changeForm('series', args);
                      if (modalType === ActionTypes.CREATE || !modalState.disableLinkToggle)
                        changeLinkRefenrece(null)
                    }}
                    width="250px"
                    disabled={modalState.type !== 'Series' || modalState.disableSeries}
                  />
                }
              />

              <InputComponent
                label="Seasons"
                input={
                  <Select
                    text={!modalState.seasons ? "Please select a preferred season." : modalState.seasons.name}
                    list={modalState.series ? modalState.series.seasons : []}
                    onSelect={(args) => {
                      changeForm('seasons', args);
                      if (modalType === ActionTypes.CREATE || !modalState.disableLinkToggle)
                        changeLinkRefenrece(null)
                    }}
                    width="250px"
                    disabled={modalState.type !== 'Series' || modalState.disableSeason}
                  />
                }
              />

              {(modalType == ActionTypes.UPDATE || modalType === ActionTypes.REPEAT) && (
                <InputComponent
                  label="Title Group"
                  input={
                    <Select
                      text={
                        modalState.titleGroup
                          ? modalState.titleGroup.name
                          : 'Please select a preferred title group'
                      }
                      value={modalState.titleGroup}
                      list={titleGroupList}
                      onSelect={(args) => {
                        changeForm('titleGroup', args);
                        if (modalType === ActionTypes.CREATE || !modalState.disableLinkToggle)
                          changeLinkRefenrece(null)
                      }}
                      width="250px"
                      disabled={modalState.type !== "TitleGroup" || modalState.disableTitleGroup}
                    />
                  }
                />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                flex: 1,
                width: '50%',
              }}
            >
              {(modalType == ActionTypes.UPDATE || modalType === ActionTypes.MULTIPLEUPDATE) && (
                <>
                  <InputComponent
                    label="Day of Week"
                    input={
                      <Select
                        text={modalState.dayOfWeek?.name}
                        value={modalState.dayOfWeek?.name}
                        list={daysOfWeek}
                        onSelect={(args) => {
                          changeForm('dayOfWeek', args);
                        }}
                        width="250px"
                        disabled={modalState.disableDayOfWeek}
                      />
                    }
                  />
                </>
              )}

              <InputComponent
                label="Start Time*"
                input={
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 15 }}
                  >
                    <div style={{ width: '149px' }}>
                      <OpTimeInput
                        value={modalState.startTime}
                        onChange={(e) => changeForm('startTime', e)}
                        width="full"
                        enabled={(modalType === ActionTypes.CREATE && Object.keys(recurencePattern).length !== 0) || !modalState.disableStartTime}
                      // TASK-5878 PROD ISSUE (13NOV2024): Removing this `disabled` prop
                      // Start Time text field should NEVER be disabled even when linking.
                      />
                    </div>
                    <Switch
                      label="Link"
                      labelStyle={{ color: '#6D6D73' }}
                      text={['No', 'Yes']}
                      active={modalState.hasLink}
                      disabled={modalState.disableLinkToggle}
                      onSwitch={() => {
                        handleToggleLink(!modalState.hasLink);
                      }}
                      blurredOnInactive={false}
                      cssClass={style.switchClass}
                    />
                  </div>
                }
              />

              <InputComponent
                label={'Link Reference*'}
                input={
                  <Select
                    text={
                      modalState.linkReference
                        ? modalState.linkReference.name
                        : 'Please select an existing block'
                    }
                    list={references}
                    onSelect={(args) => {
                      changeLinkRefenrece(args);
                      handleStartTimeChange(recurencePattern)
                      handleTitleChange(args)
                    }}
                    width="250px"
                    disabled={modalState.disableLinkReference || !modalState.hasLink}
                  />
                }
              />

              <InputComponent
                label={'End Time*'}
                input={
                  <div style={{ width: '250px' }}>
                    {/* <CustomTimeInput
                      value={modalState.endTime}
                      onChange={(e) => changeForm('endTime', e)}
                    /> */}
                    <OpTimeInput
                      value={modalState.endTime}
                      onChange={(e) => changeForm('endTime', e)}
                      width="full"
                      enabled={!modalState.disableEndTime}
                    />
                  </div>
                }
              />

              <InputComponent
                label={'Layout'}
                input={
                  <Select
                    text={modalState.layout}
                    list={Layouts}
                    onSelect={(args) => {
                      changeForm('layout', args.name);
                    }}
                    width="250px"
                    disabled={modalState.disableLayout}
                  />
                }
              />
              {modalType == ActionTypes.CREATE && (
                <InputComponent
                  label="Title Group"
                  input={
                    <Select
                      text={
                        modalState.titleGroup
                          ? modalState.titleGroup.name
                          : 'Please select a preferred title group'
                      }
                      value={modalState.titleGroup}
                      list={titleGroupList}
                      onSelect={(args) => {
                        changeForm('titleGroup', args);
                        if (modalType === ActionTypes.CREATE || !modalState.disableLinkToggle)
                          changeLinkRefenrece(null)
                      }}
                      width="250px"
                      disabled={modalState.disableTitleGroup}
                    />
                  }
                />
              )}
            </div>
          </div>
          <hr></hr>
          {/* Recurrence settings */}
          {(modalType == ActionTypes.CREATE || modalType == ActionTypes.REPEAT) && (
            <div style={{ display: 'flex' }}>
              <ReccurencePatternComponent
                isRegular={true}
                patterns={
                  modalState.hasLink && modalState.type !== 'Repeat'
                    ? [
                      { id: 2, name: 'Daily' },
                      { id: 1, name: 'After Link' },
                    ]
                    : [
                      { id: 2, name: 'Daily' },
                    ]
                }
                onChangeRecurrence={(args) => {
                  setRecurencePattern(args)
                  handleStartTimeChange(args)
                }}
                numberOfWeeks={1}
                type="dropdown"
                includeWeekSelection={false}
              />
            </div>
          )}
          <div
            className="errorValidation"
            style={{ whiteSpace: 'initial', width: '100%', textAlign: 'right', marginTop: '10px', }}
          >
            {modalState.errorMessage}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '12px',
            }}
          >
            <Button
              defaultBtn
              text="Cancel"
              onClick={() => setIsDialogOpen(false)}
            />

            <Button
              text={modalType == ActionTypes.UPDATE ? 'Update' : 'Insert'}
              type="button"
              onClick={() => {
                handleModifyBlock({
                  ...blockData,
                  EndTime: modalState.endTime,
                  StartTime: modalState.startTime,
                  DayOfWeek: modalState.dayOfWeek,
                  Week: 1,
                  Genre: modalState.genre,
                  Layout: modalState.layout,
                  IsLink: modalState.hasLink,
                  IsSequential: modalState.sequential,
                  type: modalState.type,
                  SeasonID: modalState.seasons && modalState.seasons.id?.toString(),
                  SeriesID: modalState.series && modalState.series.id,
                  SeriesName: modalState.series && modalState.series.name,
                  TitleGroupID: modalState.titleGroup && modalState.titleGroup.id,
                  TitleGroupName: modalState.titleGroup && modalState.titleGroup.name,
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModifyBlock;
