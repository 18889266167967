/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { OpButton } from 'op2mise-react-widgets';
import config from '../../op-datagrid.config.json';
import { useGetPresentationService, usePostPresentationService } from '../../hooks';
import {
  AutoGroupHeaderTemplate,
  FooterTemplate,
  LoaderTemplate,
  LoaderTemplateForRow,
  OverlayTemplate,
  ScrollPatcherTemplate,
} from '../../Templates';
import {
  applyCapitalization,
  applyClearAllFilter,
  applyFilterParams,
  applyShadesOnBookedColumn,
  applySharedColumnProperties,
  applyStyleOnChildHeaderCell,
  applyTooltips,
  applyVerticalColumns,
  getRowsToDisplay,
  gridRowIdHelper,
  gridRowSortHelper,
  gridStateHelper,
  gridStyleHelper,
  onCellEditingStoppedEvent,
  onCellKeyDownEvent,
  onCellMouseDownEvent,
  onCellValueChangedEvent,
  onColumnVisibleEvent,
  onFilterChangedEvent,
  onGridPreDestroyedEvent,
  onGridReadyEvent,
  onGridSizeChangedEvent,
  onModelUpdatedEvent,
  onRowDataUpdatedEvent,
  onRowSelectedEvent,
  onSelectionChangedEvent,
  onSortChangedEvent,
  onStateUpdatedEvent,
} from '../../utils';
{/* #ADD MISSING -> Tag for rework in DataGrid Migration */}
// import { GetGridStateAPI } from 'api';
import { Themes } from '../../types';
import '../../stylesheets/ag-theme-op2mise-2.css';
import '../../stylesheets/ag-theme-op2mise.css';
import '../../stylesheets/ag-theme-op2mise-simple.css';
import '../../OpDataGrid.css';

const { Constants } = config;

export function DualGrid(props) {

  const { alignedGrid, leftGrid, rightGrid } = props;

  // (♦) Refs
  const leftGridRef = React.useRef(null);
  const rightGridRef = React.useRef(null);

  // (♦) Custom Hooks Definition
  const { response: leftGridResponse, getApi: getLeftGridApi } = useGetPresentationService();
  const { response: rightGridResponse, getApi: getRightGridApi } = useGetPresentationService();
  const { saveApi } = usePostPresentationService();

  // (♦) Left Grid States
  const [leftGridAutoGroupColumnDefinition, setLeftGridAutoGroupColumnDefinition] = React.useState(leftGrid.autoGroupColumnDef);
  const [leftGridColumnDefs, setLeftGridColumnDefs] = React.useState(leftGrid.columns);
  const [leftGridData, setLeftGridData] = React.useState(leftGrid.rows);
  const [leftGridPageSize, setLeftGridPageSize] = React.useState(leftGrid.paginationPageSize);
  const [leftGridRowsToDisplay, setLeftGridRowsToDisplay] = React.useState(leftGrid.rows.length);
  const [leftGridSelectedRows, setLeftGridSelectedRows] = React.useState(0);
  const [leftGridState, setLeftGridState] = React.useState(null);
  const [isLeftGridFirstDataRendered, setIsLeftGridFirstDataRendered] = React.useState(false);
  const [isLeftGridReady, setIsLeftGridReady] = React.useState(false);
  const [isLeftGridRowStillProcessing, setIsLeftGridRowStillProcessing] = React.useState(false);
  const [isLeftGridStillProcessing, setIsLeftGridStillProcessing] = React.useState(true);
  
  // (♦) Right Grid States
  const [rightGridAutoGroupColumnDefinition, setRightGridAutoGroupColumnDefinition] = React.useState(rightGrid.autoGroupColumnDef);
  const [rightGridColumnDefs, setRightGridColumnDefs] = React.useState(rightGrid.columns);
  const [rightGridData, setRightGridData] = React.useState(rightGrid.rows);
  const [rightGridPageSize, setRightGridPageSize] = React.useState(rightGrid.paginationPageSize);
  const [rightGridRowsToDisplay, setRightGridRowsToDisplay] = React.useState(rightGrid.rows.length);
  const [rightGridSelectedRows, setRightGridSelectedRows] = React.useState(0);
  const [rightGridState, setRightGridState] = React.useState(null);
  const [isRightGridFirstDataRendered, setIsRightGridFirstDataRendered] = React.useState(false);
  const [isRightGridReady, setIsRightGridReady] = React.useState(false);
  const [isRightGridRowStillProcessing, setIsRightGridRowStillProcessing] = React.useState(false);
  const [isRightGridStillProcessing, setIsRightGridStillProcessing] = React.useState(true);

  // (♦) Generic States
  const [columnIsWithChildren, setColumnIsWithChildren] = React.useState(false);
  const [gridHasVerticalColumns, setGridHasVerticalColumns] = React.useState(false);
  const [gridHasHorizontalScrollbar, setGridHasHorizontalScrollbar] = React.useState(false);
  const [gridHeaderHeight, setGridHeaderHeight] = React.useState(0);

  // (♦) Memoized Functions/Callbacks 
  const client = React.useMemo(() => ( isLeftGridReady && isRightGridReady ), [ isLeftGridReady, isRightGridReady ]);
  
  const gridWrapperStyle = React.useCallback((buffer) => {
    // Provides stylesheet on the Grid Container
    return gridStyleHelper({ gridHeightBuffer: buffer })
  }, []);

  const checkForScrollbars = React.useCallback((params) => {
    const gridId = params.api.getGridId();
    const gridHorizontalScrollViewport = document.querySelector(`[grid-id="${gridId}"] .ag-body-horizontal-scroll-viewport`);
    const gridHeader = document.querySelector(`[grid-id="${gridId}"] .ag-header-viewport`);
  
    // Cache the computed values
    const newGridHeaderHeight = gridHeader ? gridHeader.getBoundingClientRect().height.toFixed(0) : 0;
    const hasHorizontalScrollbar = gridHorizontalScrollViewport.scrollWidth > gridHorizontalScrollViewport.clientWidth;
  
    // Only update state if values have changed
    if (newGridHeaderHeight !== gridHeaderHeight) {
      setGridHeaderHeight(newGridHeaderHeight);
    }
  
    if (hasHorizontalScrollbar !== gridHasHorizontalScrollbar) {
      setGridHasHorizontalScrollbar(hasHorizontalScrollbar);
    }
  }, [gridHeaderHeight, gridHasHorizontalScrollbar]);

  const handleAutoGroupColumnProperties = React.useCallback((position, setAutoGroupColumnDefinition) => {
    const autoGroupColumnDef =  position === 'left' ? leftGrid.autoGroupColumnDef : rightGrid.autoGroupColumnDef;
    // check if property is defined
    if (autoGroupColumnDef) {
      if (!Object.keys(autoGroupColumnDef).length) return;

      const hasWeekVerticalColumns = columns.some(
        (column) => column.field?.includes('week') && column.field?.includes('-vertical')
      );
      
      setAutoGroupColumnDefinition(() => ({
        ...autoGroupColumnDef,
        headerComponent: AutoGroupHeaderTemplate,
        headerClass: `${autoGroupColumnDef.headerClass || ''} ${hasWeekVerticalColumns ? 'ag-header-none-vertical' : ''}`,
        ...(autoGroupColumnDef?.headerTooltip ? {} : { headerTooltip: autoGroupColumnDef.headerName }),
        ...(autoGroupColumnDef?.tooltipValueGetter ? {} : { tooltipValueGetter: (params) => params.value }),
        filterParams: {
          ...autoGroupColumnDef?.filterParams,
          ...(autoGroupColumnDef?.applyMiniFilterWhileTyping ? {} : { applyMiniFilterWhileTyping: true }),
        },
      }));
    }
  }, [leftGrid.autoGroupColumnDef, rightGrid.autoGroupColumnDef,columnIsWithChildren]);

  const handleDefaultColDefs = React.useCallback((grid) => {
    /**
     * Dynamically sets default column definitions for ag-Grid.
     * Applies the given default settings to the `defaultColDef` property of the provided `gridOptions` object.
     */
    const gridObject = grid;

    return {
      editable: gridObject.editable,
      filter: gridObject.filter,
      lockPinned: gridObject.lockPinned,
      resizable: gridObject.resizable,
      sortable: gridObject.sortable,
      minWidth: Constants.ColDef.minWidth,
    }
  }, []);

  const updateRowData = React.useCallback(() => {
    let leftGridRowTimer;
    let rightGridRowTimer;

    if (client) {
      setIsLeftGridRowStillProcessing(true);
      setIsRightGridRowStillProcessing(true);
    }

    leftGridRowTimer = setTimeout(() => setIsLeftGridRowStillProcessing(false), 300);
    rightGridRowTimer = setTimeout(() => setIsRightGridRowStillProcessing(false), 300);

    return () => {
      clearTimeout(leftGridRowTimer);
      clearTimeout(rightGridRowTimer);
    }
  }, [client]);

  // (♦) Auto Runs: Customized Functions
  const handleCapitalization = React.useCallback((columnInstance) =>
    // Auto run for capitalizing header name and header tooltip texts
    applyCapitalization({ columnInstance })
    , []);

  const handleClearAllFilter = React.useCallback((columnInstance) =>
    // Auto run for setting up Clear All Filter feature
    applyClearAllFilter({ columnInstance })
    , []);

  const handleFilterParams = React.useCallback((columnInstance) =>
      // Auto run for setting up Automatic Filter on Input Change Feature
      applyFilterParams({ columnInstance })
    , []);

  const handleBookedColumn = React.useCallback((columnInstance) =>
    // Auto run for setting background color (light/dark colors) on columns that contains `book` in the `field` column attribute
    applyShadesOnBookedColumn({ columnInstance, disableHighlightOnBookedColumn: leftGrid.disableHighlightOnBookedColumn, setColumnIsWithChildren })
    , [leftGrid.disableHighlightOnBookedColumn]);

  const handleChildHeaderCell = React.useCallback((columnInstance) =>
    // Auto run for setting background color (pine-gree color) on CHILD header cells
    applyStyleOnChildHeaderCell({ columnInstance })
    , []);

  const handleSharedColumnProperties = React.useCallback((columnInstance) =>
    // Auto run for memoized callback to apply shared column properties accross multiple columns.
    applySharedColumnProperties({ columnInstance, sharedColumnProperties: leftGrid.sharedColumnProperties })
    , [leftGrid.sharedColumnProperties]);

  const handleTooltips = React.useCallback((columnInstance) => 
    // Auto run for setting up tooltips for header and row cells
    applyTooltips({ columnInstance })
    , []);

  const handleVerticalColumns = React.useCallback((columnInstance) => 
    // Auto run for applying Vertical Column Styling on Header
    applyVerticalColumns({ columnInstance, setGridHasVerticalColumns })
    , []);

  const executeAllAutoRuns = React.useCallback(() => {
    // Executes all auto-run functions
    const leftGridColumnInstance = leftGridColumnDefs;
    const rightGridColumnInstance = rightGridColumnDefs;
    const autoRuns = [
      handleBookedColumn,
      handleCapitalization,
      handleChildHeaderCell,
      handleClearAllFilter,
      handleFilterParams,
      handleSharedColumnProperties,
      handleTooltips,
      handleVerticalColumns,
    ];
    const refactoredLeftGridColumns = autoRuns.reduce((column, func) => func(column), leftGridColumnInstance);
    const refactoredRightGridColumns = autoRuns.reduce((column, func) => func(column), rightGridColumnInstance);
    setLeftGridColumnDefs(refactoredLeftGridColumns);
    setRightGridColumnDefs(refactoredRightGridColumns);
  }, [
    leftGridColumnDefs,
    rightGridColumnDefs,
    handleBookedColumn,
    handleCapitalization,
    handleChildHeaderCell,
    handleClearAllFilter,
    handleFilterParams,
    handleSharedColumnProperties,
    handleTooltips,
    handleVerticalColumns,
  ]);

  // (♦) Grid Event Handler for: onCellEditingStopped
  const handleOnCellEditingStopped = React.useCallback((params, position) => {
    const { onCellEditingStopped } = position === 'left'
      ? { onCellEditingStopped: leftGrid.onCellEditingStopped }
      : { onCellEditingStopped: rightGrid.onCellEditingStopped };

    onCellEditingStoppedEvent({ params, onCellEditingStopped });
  }, []);

  // (♦) Grid Event Handler for: onCellKeyDown
  const handleOnCellKeyDown = React.useCallback((params, position) => {
    const { onCellKeyDown } = position === 'left'
      ? { onCellKeyDown: leftGrid.onCellKeyDown }
      : { onCellKeyDown: rightGrid.onCellKeyDown };

    onCellKeyDownEvent({ params, onCellKeyDown });
  }, []);

  // (♦) Grid Event Handler for: onCellMouseDown event
  const handleOnCellMouseDownEvent = React.useCallback((params, position) => {
    const { onCellMouseDown } = position === 'left'
      ? { onCellMouseDown: leftGrid.onCellMouseDown }
      : { onCellMouseDown: rightGrid.onCellMouseDown };

    onCellMouseDownEvent({ params, onCellMouseDown })
  }, []);

  // (♦) Grid Event Handler for: onCellValueChanged event
  const handleOnCellValueChanged = React.useCallback((params, position) => {
    const { onCellValueChanged } = position === 'left'
    ? { onCellValueChanged: leftGrid.onCellValueChanged }
    : { onCellValueChanged: rightGrid.onCellValueChanged };

    onCellValueChangedEvent({ params, onCellValueChanged })
  }, []);

  // (♦) Grid Event Handler for: onColumnVisible event
  const handleOnColumnvisible = React.useCallback((params, position) => {
    const { columnDefinition, gridRef, onColumnVisible} = position === 'left'
      ? { onColumnVisible: leftGrid.onColumnVisible, gridRef: leftGridRef, columnDefinition: leftGridColumnDefs }
      : { onColumnVisible: rightGrid.onColumnVisible, gridRef: rightGridRef, columnDefinition: rightGridColumnDefs };

    onColumnVisibleEvent({ params, columnDefinition, gridRef, onColumnVisible });
  }, [leftGridColumnDefs, rightGridColumnDefs]);

  // (♦) Grid Event Handler for: onGridSizeChanged event
  const handleOnGridSizeChanged = React.useCallback((params, position) => {
    const onGridSizeChanged = position === 'left' ? leftGrid.onGridSizeChanged : rightGrid.onGridSizeChanged;
    onGridSizeChangedEvent({ params, onGridSizeChanged });
  }, []);

  // (♦) Grid Event Handler for: onModelUpdated event
  const handleOnModelUpdated = React.useCallback((params, position) => {
    const { gridRef, setRowsToDisplay } = position === 'left'
      ? { gridRef: leftGridRef, setRowsToDisplay: setLeftGridRowsToDisplay }
      : { gridRef: rightGridRef, setRowsToDisplay: setRightGridRowsToDisplay };

    onModelUpdatedEvent({ gridRef, params, setRowsToDisplay });
  }, [leftGridRowsToDisplay, rightGridRowsToDisplay]);

  // (♦) Grid Event Handler for: onRowDataUpdated event
  const handleOnRowDataUpdated = React.useCallback((params, position) => {
    const onRowDataUpdated = position = 'left' ? leftGrid.onRowDataUpdated : rightGrid.onRowDataUpdated;
    const { gridRef } = position === 'left'
      ? { gridRef: leftGridRef }
      : { gridRef: rightGridRef };
      
    onRowDataUpdatedEvent({ gridRef, params, onRowDataUpdated });
  }, [leftGrid, rightGrid]);

   // (♦) Grid Event Handler for: onRowSelected event
   const handleOnRowSelected = React.useCallback((params, position) => {
    const onRowSelected = position === 'left' ? leftGrid.onRowSelected : rightGrid.onRowSelected;
    onRowSelectedEvent({ params, onRowSelected });
  }, [leftGrid, rightGrid]);

  // (♦) Grid Event Handler for: onSelectionChanged event
  const handleOnSelectionChanged = React.useCallback((params, position) => {
    const { gridRef, onSelectionChanged, setSelectedRows } = position === 'left'
      ? { gridRef: leftGridRef, onSelectionChanged: leftGrid.onSelectionChanged, setSelectedRows: setLeftGridSelectedRows }
      : { gridRef: rightGridRef, onSelectionChanged: rightGrid.onSelectionChanged, setSelectedRows: setRightGridSelectedRows };

    // WORKAROUND: THIS BLOCK CLOSES ALL EXPANDED ROWS ON THE OTHER GRID
    // Consider removing this block if we want to expand two Detail Rows on Both Grids
    let rowsToDisplay = position === 'left' ? getRowsToDisplay(rightGridRef.current) : getRowsToDisplay(leftGridRef.current);
    if (position === 'left') {
      rowsToDisplay.map(data => {
        data.expanded = false;
        return data;
      });
    } else {
      rowsToDisplay.map(data => {
        data.expanded = false;
        return data;
      });
    }

    onSelectionChangedEvent({ params, gridRef, onSelectionChanged, setSelectedRows });
  }, []);

  // (♦) Grid Event Handler for: onSortChanged event
  const handleOnSortChanged = React.useCallback((params, position) => {
    const { gridRef, onSortChanged } = position === 'left'
      ? { gridRef: leftGridRef, onSortChanged: leftGrid.onSortChanged }
      : { gridRef: rightGridRef, onSortChanged: rightGrid.onSortChanged };

    onSortChangedEvent({ params, gridRef, onSortChanged });
  }, []);

  // (♦) Grid Event Handler for: onStateUpdated event
  const handleOnStateUpdated = React.useCallback((params, position) => {
    const { gridRef, onStateUpdated } = position === 'left'
      ? { gridRef: leftGridRef, onStateUpdated: leftGrid.onStateUpdated }
      : { gridRef: rightGridRef, onStateUpdated: rightGrid.onStateUpdated };

    onStateUpdatedEvent({ params, gridRef, onStateUpdated });
  }, []);

  // (♦) Grid Event Handler for: onColumnResized event
  const handleOnColumnResized = React.useCallback((params, position) => {
    const onColumnResized = position === 'left' ? leftGrid.onColumnResized : rightGrid.onColumnResized;

    if (onColumnResized) onColumnResized(params);
    checkForScrollbars(params);
  }, []);

  // (♦) Grid Event Handler for: onFilterChanged event
  const handleOnFilterChanged = React.useCallback((params, position) => {
    const { gridRef, onFilterChanged } = position === 'left'
      ? { gridRef: leftGridRef, onFilterChanged: leftGrid.onFilterChanged }
      : { gridRef: rightGridRef, onFilterChanged: rightGrid.onFilterChanged };
    
    onFilterChangedEvent({ params, gridRef, onFilterChanged });
    checkForScrollbars(params);
  }, [checkForScrollbars]);

  // (♦) Grid Event Handler for: onGridPreDestroyed event
  const handleOnGridPreDestroyed = React.useCallback((params, position) => {
    const { gridRef, gridName, onGridPreDestroyed } = position === 'left'
      ? { gridRef: leftGridRef, gridName: leftGrid.gridName, onGridPreDestroyed: leftGrid.onGridPreDestroyed }
      : { gridRef: rightGridRef, gridName: rightGrid.gridName, onGridPreDestroyed: rightGrid.onGridPreDestroyed };

    if (gridName) {
      const requestBody = {
        grid: gridName,
        state: JSON.stringify(params.state),
      };
      saveApi('User/SaveGridState', requestBody);
    }

    onGridPreDestroyedEvent({ params, gridName, onGridPreDestroyed, gridRef });
  }, []);

  // (♦) Grid Event Handler for: onGridReady event
  const handleOnGridReady = React.useCallback((params, position) => {
    const onGridReady = position === 'left' ? leftGrid.onGridReady : rightGrid.onGridReady;
    onGridReadyEvent({ params, columnIsWithChildren, gridHasVerticalColumns, onGridReady });
    
    if (position === 'left') setIsLeftGridReady(true);
    else setIsRightGridReady(true);

    checkForScrollbars(params);
  }, [columnIsWithChildren, gridHasVerticalColumns, checkForScrollbars]);


  // (♦) Use Effect Hooks
  React.useEffect(() => {
    /*
     * Side-effect responsible for invoking all auto-run functions
     * Auto-run functions are customized features.
     * See: /utils/autoRuns
     */

    executeAllAutoRuns();
  }, []);

  React.useEffect(() => {
    /**
     * Side-effect for fetching Grid State
     * Pre-configures Grid State before rendering into the Client
     */
    const fetchGridState = (grid, setGridState, getApi) => {
      const gridName = grid.gridName;
      if (gridName) {
        getApi('User/GetGridState', { grid: gridName });
      } else {
        setGridState(true)
      }
    };
  
    fetchGridState(leftGrid, setLeftGridState, getLeftGridApi);
    fetchGridState(rightGrid, setRightGridState, getRightGridApi);
  }, [leftGrid.gridName, rightGrid.gridName]);

  React.useEffect(() => {
    /**
     * Side-effect for capturing the response body from
     * the GetGridState API Call
     */
    if (leftGridResponse) {
      const state = leftGridResponse.state ? JSON.parse(leftGridResponse.state) : {};
      if (leftGridResponse.state){
        gridRowSortHelper(
          state,
          leftGrid.defaultSort,
          leftGridRef,
          leftGridColumnDefs
        )
        gridStateHelper(state, leftGridRef);
      }

      setLeftGridState(state);
    }

    if (rightGridResponse) {
      const state =rightGridResponse.state ? JSON.parse(rightGridResponse.state) : {};
      if (rightGridResponse.state){
        gridRowSortHelper(
          state,
          rightGrid.defaultSort,
          rightGridRef,
          rightGridColumnDefs
        )
        gridStateHelper(state, rightGridRef);
      } 
      setRightGridState(state);
    }
  }, [leftGridResponse, rightGridResponse]);

  React.useEffect(() => {
    /**
     * Side-effect for handling auto grouped columns/rows
     */
    handleAutoGroupColumnProperties('left', setLeftGridAutoGroupColumnDefinition);
    handleAutoGroupColumnProperties('right', setRightGridAutoGroupColumnDefinition);
  }, [leftGrid.autoGroupColumnDef, rightGrid.autoGroupColumnDef]);

  React.useEffect(() => {
   /**
     * Updates columnDefs gridOption when column changes
     */

    leftGridRef.current?.api?.setGridOption('columnDefs', leftGridColumnDefs);
    rightGridRef.current?.api?.setGridOption('columnDefs', rightGridColumnDefs);
  }, [leftGrid.columns, rightGrid.columns]);

  React.useEffect(() => {
    /*
     * Left Grid Loader
     * Side-effect responsible for Invoking Loading effect from external factor (API call)
     * Decides whether to use Grid level or Row level loaders
     */

    let timerForGrid;
    let timerForRow;
    const isLeftGridEstablished = isLeftGridFirstDataRendered;
    const isLeftGridLoadingExternally = leftGrid.loading;
    const isLeftGridLoadingInternally = !isLeftGridReady;
    const isLeftGridStateFetched = leftGridState;
    const shouldLeftGridLoadWhenLoadingExternally = isLeftGridLoadingExternally && !isLeftGridEstablished;

    if (isLeftGridLoadingExternally && !shouldLeftGridLoadWhenLoadingExternally) {
      setIsLeftGridRowStillProcessing(true);
    } else {
      timerForRow = setTimeout(() => setIsLeftGridRowStillProcessing(false), 300);
    }

    if (!shouldLeftGridLoadWhenLoadingExternally && !isLeftGridLoadingInternally && isLeftGridStateFetched) {
      timerForGrid = setTimeout(() => setIsLeftGridStillProcessing(false), 300);
    } else {
      setIsLeftGridStillProcessing(true);
    }

    return () => {
      clearTimeout(timerForGrid);
      clearTimeout(timerForRow);
    };
  }, [
    isLeftGridFirstDataRendered,
    isLeftGridReady, 
    leftGridState,
    leftGrid.loading,
  ]);

  React.useEffect(() => {
    /*
     * Right Grid Loader
     * Side-effect responsible for Invoking Loading effect from external factor (API call)
     * Decides whether to use Grid level or Row level loaders
     */

    let timerForGrid;
    let timerForRow;
    const isRightGridEstablished = isRightGridFirstDataRendered;
    const isRightGridLoadingExternally = rightGrid.loading;
    const isRightGridLoadingInternally = !isRightGridReady;
    const isRightGridStateFetched = rightGridState;
    const shouldRightGridLoadBasedOnExternalLoading = isRightGridLoadingExternally && !isRightGridEstablished;

    if (rightGrid.loading && !shouldRightGridLoadBasedOnExternalLoading) {
      setIsRightGridRowStillProcessing(true);
    } else {
      timerForRow = setTimeout(() => setIsRightGridRowStillProcessing(false), 300);
    }

    if (!shouldRightGridLoadBasedOnExternalLoading && !isRightGridLoadingInternally && isRightGridStateFetched) {
      timerForGrid = setTimeout(() => setIsRightGridStillProcessing(false), 300);
    } else {
      setIsRightGridStillProcessing(true);
    }

    return () => {
      clearTimeout(timerForGrid);
      clearTimeout(timerForRow);
    };
  }, [
    isRightGridFirstDataRendered,
    rightGridState,
    isRightGridReady,
    rightGrid.loading,
  ]);

  React.useEffect(() => {
    /*
     * Side-effect responsible for hiding the default overlay message for empty rows
     * Prevents overlapping with BootstrapSpinner
     */

    if (!isLeftGridReady || leftGrid.loading) {
      if (leftGridRef.current?.api) leftGridRef.current?.api.hideOverlay();
    }

    if (!isRightGridReady || rightGrid.loading) {
      if (rightGridRef.current?.api) rightGridRef.current?.api.hideOverlay();
    }
  }, [
    isLeftGridReady,
    isRightGridReady,
    leftGrid.loading, 
    rightGrid.loading,
  ]);

  React.useEffect(() => {
    /*
     * Side-effect responsible for setting up row-data for the Grid
     */

    updateRowData();

    const leftGridRowData = leftGrid.rows;
    const leftGridRowDataLength = leftGridRowData.length;
    const rightGridRowData = rightGrid.rows;
    const rightGridRowDataLength = rightGridRowData.length;

    // Re-assign values on the grid's rowData when arrays are mutated
    setLeftGridData(leftGridRowData);
    setRightGridData(rightGridRowData);

    // Updates the number of rows label located in the Footer template
    setLeftGridRowsToDisplay(leftGridRowDataLength);
    setRightGridRowsToDisplay(rightGridRowDataLength);

    if (leftGridRowDataLength > 200) {
      // Dynamically set a new page size if number of rows start to exceed 200
      const leftGridPageSize = (Math.ceil(leftGridRowDataLength / 100) * 100);
      setLeftGridPageSize(leftGridPageSize);
    };

    if (rightGridRowDataLength > 200) {
      // Dynamically set a new page size if number of rows start to exceed 200
      const rightGridPageSize = (Math.ceil(rightGridRowDataLength / 100) * 100);
      setRightGridPageSize(rightGridPageSize);
    };

  }, [rightGrid.rows, leftGrid.rows, updateRowData]);

  return (
    <div style={{ height: '100%', width: '100%'}}>
      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ height: '100%', flex: 5 }}>
          <span style={{ color: '#6d6d73', fontSize: '14px', fontWeight: 400, marginBottom: '5px' }}>{leftGrid.gridLabel}</span>
          <div className={props.theme === Themes.PRIMARY ? columnIsWithChildren ? 'ag-theme-op2mise-2' : 'ag-theme-op2mise' : 'ag-theme-op2mise-simple'} style={gridWrapperStyle(leftGrid.gridHeightBuffer)}>
            <LoaderTemplate {...{
              isLoading: isLeftGridStillProcessing || isRightGridStillProcessing,
              height: gridWrapperStyle(leftGrid.gridHeightBuffer).height,
            }} />
            <LoaderTemplateForRow {...{
              isLoading: isLeftGridRowStillProcessing && !isLeftGridStillProcessing,
              height: gridWrapperStyle(leftGrid.gridHeightBuffer + 51).height,
            }} />
            <ScrollPatcherTemplate
              isVisible={isLeftGridReady && !leftGrid.loading && gridHasHorizontalScrollbar}
              gridHeaderHeight={gridHeaderHeight}
              theme={props.theme}
            />
            <AgGridReact
              gridId={window.crypto.randomUUID()}
              alignedGrids={alignedGrid ? [rightGridRef] : []}
              autoGroupColumnDef={leftGridAutoGroupColumnDefinition}
              columnDefs={leftGridColumnDefs}
              defaultColDef={handleDefaultColDefs(leftGrid)}
              detailCellRenderer={leftGrid.detailCellRenderer}
              detailCellRendererParams={leftGrid.detailCellRendererParams}
              detailRowHeight={leftGrid.detailRowHeight}
              getRowId={(params) => gridRowIdHelper(params, leftGrid.rowId)}
              groupDefaultExpanded={leftGrid.groupDefaultExpanded}
              masterDetail={leftGrid.masterDetail}
              noRowsOverlayComponent={() => (<OverlayTemplate customVerbiage={leftGrid.customVerbiage} />)}
              onCellEditingStopped={(params) => handleOnCellEditingStopped(params, 'left')}
              onCellKeyDown={(params) => handleOnCellKeyDown(params, 'left')}
              onCellMouseDown={(params) => handleOnCellMouseDownEvent(params, 'left')}
              onCellValueChanged={(params) => handleOnCellValueChanged(params, 'left')}
              onColumnResized={(params) => handleOnColumnResized(params, 'left')}
              onColumnVisible={(params) => handleOnColumnvisible(params, 'left')}
              onFilterChanged={(params) => handleOnFilterChanged(params, 'left')}
              onFirstDataRendered={(params) => setIsLeftGridFirstDataRendered(true)}
              onGridPreDestroyed={(params) => handleOnGridPreDestroyed(params, 'left')}
              onGridReady={(params) => handleOnGridReady(params, 'left')}
              onGridSizeChanged={(params) => handleOnGridSizeChanged(params, 'left')}
              onModelUpdated={(params) => handleOnModelUpdated(params, 'left')}
              onRowDataUpdated={(params) => handleOnRowDataUpdated(params, 'left')}
              onRowSelected={(params) => handleOnRowSelected(params, 'left')}
              onSelectionChanged={(params) => handleOnSelectionChanged(params, 'left')}
              onSortChanged={(params) => handleOnSortChanged(params, 'left')}
              onStateUpdated={(params) => handleOnStateUpdated(params, 'left')}
              pagination={leftGrid.pagination}
              paginationPageSize={leftGridPageSize}
              ref={leftGridRef}
              rowData={leftGridData}
              rowClassRules={{
                ...leftGrid.rowClassRules,
                ...(leftGrid.showAlternativeRowHighlight && {
                  'ag-row-alternate': (params) => params && params.rowIndex % 2 !== 0,
                }),
              }}
              rowSelection={leftGrid.enableMultipleRowSelection ? 'multiple' : 'single'}
              rowMultiSelectWithClick={leftGrid.enableMultipleRowSelection}
              suppressMovableColumns={leftGrid.suppressMovableColumns}
              suppressRowClickSelection={leftGrid.useCheckboxForRowSelection}
              {...leftGrid.enableMultipleColumnSorting && { multiSortKey: 'ctrl' }}
              {...Constants.GridOptions}
              
            />
          </div>
          <FooterTemplate {...{
            loading: isLeftGridStillProcessing || isLeftGridRowStillProcessing,
            pagination: leftGrid.pagination,
            rowsToDisplay: leftGridRowsToDisplay,
            customFooterElement: leftGrid.customFooterElement,
            baseGridRef: leftGridRef
          }} />
          {leftGrid.showRowSelectionCount && (
            <p style={{ marginTop: '15px', color: '#6d6d73', fontSize: '12px', fontWeight: '400', letterSpacing: 0, lineHeight: '18px' }}>
              {leftGridSelectedRows} rows selected
            </p>
          )}
        </div>
        <div className='action-buttons'>
          {props.actions.map(action => {
            return (
              <OpButton
                key={window.crypto.randomUUID()}
                onClick={action.onClick || null}
                disabled={action.disabled || false}
                icon={action.icon}
                iconPosition={action.iconPosition}
                label={action.label}
                allowTextWrap={true}
              />
            );
          })}
        </div>
        <div style={{ height: '100%', flex: 5 }}>
          <span style={{ color: '#6d6d73', fontSize: '14px', fontWeight: 400, marginBottom: '5px' }}>{rightGrid.gridLabel}</span>
          <div className={props.theme === Themes.PRIMARY ? columnIsWithChildren ? 'ag-theme-op2mise-2' : 'ag-theme-op2mise' : 'ag-theme-op2mise-simple'} style={gridWrapperStyle(leftGrid.gridHeightBuffer)}>
            <LoaderTemplate {...{
              isLoading: isLeftGridStillProcessing || isRightGridStillProcessing,
              height: gridWrapperStyle(leftGrid.gridHeightBuffer).height,
            }} />
            <LoaderTemplateForRow {...{
              isLoading: isRightGridRowStillProcessing && !(isLeftGridStillProcessing || isRightGridStillProcessing),
              height: gridWrapperStyle(leftGrid.gridHeightBuffer + 51).height,
            }} />
            <ScrollPatcherTemplate
              isVisible={isRightGridReady && !rightGrid.loading && gridHasHorizontalScrollbar}
              gridHeaderHeight={gridHeaderHeight}
              theme={props.theme}
            />
            <AgGridReact
              gridId={window.crypto.randomUUID()}
              alignedGrids={alignedGrid ? [leftGridRef] : []}
              autoGroupColumnDef={rightGridAutoGroupColumnDefinition}
              columnDefs={rightGridColumnDefs}
              defaultColDef={handleDefaultColDefs(rightGrid)}
              detailCellRenderer={rightGrid.detailCellRenderer}
              detailCellRendererParams={rightGrid.detailCellRendererParams}
              detailRowHeight={rightGrid.detailRowHeight}
              getRowId={(params) => gridRowIdHelper(params, rightGrid.rowId)}
              groupDefaultExpanded={rightGrid.groupDefaultExpanded}
              masterDetail={rightGrid.masterDetail}
              noRowsOverlayComponent={() => (<OverlayTemplate customVerbiage={rightGrid.customVerbiage} />)}
              onCellEditingStopped={(params) => handleOnCellEditingStopped(params, 'right')}
              onCellKeyDown={(params) => handleOnCellKeyDown(params, 'right')}
              onCellMouseDown={(params) => handleOnCellMouseDownEvent(params, 'right')}
              onCellValueChanged={(params) => handleOnCellValueChanged(params, 'right')}
              onColumnResized={(params) => handleOnColumnResized(params, 'right')}
              onColumnVisible={(params) => handleOnColumnvisible(params, 'right')}
              onFilterChanged={(params) => handleOnFilterChanged(params, 'right')}
              onFirstDataRendered={(params) => setIsRightGridFirstDataRendered(true)}
              onGridPreDestroyed={(params) => handleOnGridPreDestroyed(params, 'right')}
              onGridReady={(params) => handleOnGridReady(params, 'right')}
              onGridSizeChanged={(params) => handleOnGridSizeChanged(params, 'right')}
              onModelUpdated={(params) => handleOnModelUpdated(params, 'right')}
              onRowDataUpdated={(params) => handleOnRowDataUpdated(params, 'right')}
              onRowSelected={(params) => handleOnRowSelected(params, 'right')}
              onSelectionChanged={(params) => handleOnSelectionChanged(params, 'right')}
              onSortChanged={(params) => handleOnSortChanged(params, 'right')}
              onStateUpdated={(params) => handleOnStateUpdated(params, 'right')}
              pagination={rightGridPageSize}
              paginationPageSize={rightGrid.pageSize}
              ref={rightGridRef}
              rowData={rightGridData}
              rowClassRules={{
                ...rightGrid.rowClassRules,
                ...(rightGrid.showAlternativeRowHighlight && {
                  'ag-row-alternate': (params) => params && params.rowIndex % 2 !== 0,
                }),
              }}
              rowSelection={rightGrid.enableMultipleRowSelection ? 'multiple' : 'single'}
              rowMultiSelectWithClick={rightGrid.enableMultipleRowSelection}
              suppressMovableColumns={rightGrid.suppressMovableColumns}
              suppressRowClickSelection={rightGrid.useCheckboxForRowSelection}
              {...rightGrid.enableMultipleColumnSorting && { multiSortKey: 'ctrl' }}
              {...Constants.GridOptions}
            />
          </div>
          <FooterTemplate {...{
            loading: isLeftGridStillProcessing || isLeftGridRowStillProcessing,
            pagination: rightGrid.pagination,
            rowsToDisplay: rightGridRowsToDisplay,
            customFooterElement: rightGrid.customFooterElement,
            baseGridRef: rightGridRef
          }} />
          {rightGrid.showRowSelectionCount && (
            <p style={{ marginTop: '15px', color: '#6d6d73', fontSize: '12px', fontWeight: '400', letterSpacing: 0, lineHeight: '18px' }}>
              {rightGridSelectedRows} rows selected
            </p>
          )}
        </div>
      </div>
    </div >
  );
}

// I need a reducer/state that will allow me to update columns/rows for DualGrid/BaseGrid/and OverviewGrid