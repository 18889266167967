import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ImportModal.module.css';
import Button from 'shared/components/button/Button';
import PrioritisationForms from 'components/prioritisation/PrioritisationForms';
import SchedulerStorage from 'components/schedule/react-scheduler/SchedulerStorage';
import { xIcon, checkIcon, downloadFileIcon } from 'assets/index';
import XLSX from 'sheetjs-style';
import { OpDataGrid } from 'op2mise-react-widgets';

export default function ImportFileModalComponent({
  closeModal,
  selectedFile,
  setSelectedFile,
  onImportFile,
  errorMessage,
  showModal,
  onExportSampleTemplate,
  modalComponent,
  onProceed,
  resultList,
}) {
  const chooseFileRef = useRef();
  const { importModalComponents } = PrioritisationForms;

  const { importFilter } = SchedulerStorage;
  const [filter, setFilter] = useState(importFilter.all);
  const [tableData, setTableData] = useState([]);
  const { importModal } = PrioritisationForms;
  const [modalState, setModalState] = useState({
    title: 'Import break pattern',
    description: '',
    exportResultFile: 'break-patterns-result-template',
    resultHeader: '',
  });

  const changeFilter = (selected) => {
    setFilter(selected);
  };

  const filterUploaded = () => {
    if (filter === importFilter.all) setTableData(resultList);
    else if (filter === importFilter.Imported)
      setTableData(resultList.filter((o) => o.imported));
    else if (filter === importFilter.NotImported)
      setTableData(resultList.filter((o) => !o.imported));
  };

  const exportExcel = () => {
    let excelData = tableData.map((value, index) => {
      return {
        'Type': value.type,
        Imported: value.imported,
        Result: value.result,
      };
    });
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileName = `${modalState.exportResultFile}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'Import Result': ws }, SheetNames: ['Import Result'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName);
  };

  useEffect(() => {
    filterUploaded();
  }, [filter, resultList]);

  const handleUploadButton = () => {
    chooseFileRef.current.click();
  };

  const onFileChangeCapture = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleCustomVerbiage = filter === importFilter.Imported ? 'No successful imports' : filter === importFilter.NotImported ? 'No failed imports' : 'No import results'

  return (
    <Modal show={showModal} centered size={modalComponent === importModalComponents.success ? "xl" : "lg"}>
      {modalComponent === importModalComponents.import && (
        <div className={styles.body}>
          <div>
            <div style={{ fontWeight: 'bolder' }}>{modalState.title}</div>
            <p className={styles.smallText}>
              Download a{' '}
              <span
                style={{
                  color: '#008662',
                  cursor: 'pointer',
                }}
                onClick={onExportSampleTemplate}
                onKeyDown={onExportSampleTemplate}
                role="presentation"
              >
                sample template{' '}
              </span>
              to see an example of the required format.
            </p>
            <p
              style={{
                marginTop: '-15px',
                color: 'red',
                fontSize: '14px',
              }}
            >
              {errorMessage}
            </p>

            <div className="d-flex">
              <Button
                text="Choose file"
                onClick={handleUploadButton}
                style={{ marginTop: '29px' }}
              />
              <input
                ref={chooseFileRef}
                hidden
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                onChange={onFileChangeCapture}
              />

              <p
                className={styles.fileName}
                title={selectedFile && selectedFile.name}
              >
                {selectedFile && selectedFile.name}
              </p>
            </div>

            <div
              style={{
                paddingTop: '10px',
              }}
            >
              <small>Acceptable file formats: Excel, CSV</small>
            </div>
          </div>

          <div className={styles.buttons}>
            <Button defaultBtn text="Cancel" onClick={closeModal} />
            <Button
              text="Import"
              onClick={onImportFile}
              disabled={!selectedFile}
            />
          </div>
        </div>
      )}
      {modalComponent === importModalComponents.confirm && (
        <div className={styles.body} style={{ textAlign: 'center' }}>
          <div>
            <p className={styles.description}>{modalState.description}</p>
            <p className={styles.description}>
              Importing will override the existing records.
            </p>
            <p className={styles.confirmationText}>
              Are you sure you want to proceed?
            </p>
          </div>

          <div style={{ marginTop: '30px' }}>
            <button
              className={`${styles.cancelButton} ${styles.marginRight10}`}
              onClick={closeModal}
              style={{ marginRight: '0' }}
              type="button"
            >
              Cancel
            </button>
            <button
              className={`${styles.saveButton} ${styles.marginRight10}`}
              onClick={onProceed}
              type="button"
            >
              Proceed
            </button>
          </div>
        </div>
      )}
      {modalComponent === importModalComponents.success && (
        <Modal.Body style={{ padding: '35px' }}>
          <div className={styles.divMessage}>
            <b> {resultList.filter((o) => o.imported).length} </b>
            slots out of <b> {resultList.length} </b>
            were successfully imported and validated!
          </div>

          <div className={`${styles.successDiv}`}>
            <OpDataGrid
              theme={'SIMPLE'}
              columns={[{
                field: 'type',
                headerName: 'Type',
              }, {
                field: 'imported',
                headerName: " ",
                suppressMenu: true,
                cellCss: 'no-border',
                maxWidth: 80,
                cellRenderer: (params) => (
                  <div style={{ textAlign: 'center', }}>
                    <img src={params.data.imported ? checkIcon : xIcon} alt="" />
                  </div>
                ),
              }, {
                field: 'result',
                headerName: "Results",
                headerToolTip: "Results",
                suppressMenu: true,
                suppressNavigable: true,
                minWidth: 180,
                maxWidth: 180,
                cellCss: 'no-border',
                cellClassRules: {
                  'success': params => params.data.imported,
                  'danger': params => !params.data.imported
                },
              }]}
              rows={tableData}
              rowClass='white-background'
              customVerbiage={handleCustomVerbiage}
              gridName="import-results"
              gridHeightBuffer={350}
              resizable={false}
              suppressContextMenu={true}
              suppressMovableColumns={true}
              sortable={false}
              pagination={false}
              columnHoverHighlight={false}
              suppressRowHoverHighlight={true}
              suppressCellSelection={true}
              filter={false}
              sharedColumnProperties={{
                cellStyle: {
                  fields: ['type', 'result', 'imported'],
                  value: { textAlign: 'left' },
                },
                flex: {
                  fields: ['type', 'result', 'imported'],
                  value: 1,
                },
                suppressHeaderMenuButton: {
                  fields: ['type', 'result', 'imported'],
                  value: true,
                },
                headerClass: {
                  fields: ['type', 'result', 'imported'],
                  value: 'ag-header-text-align-left',
                },
              }}
            />

          </div>
          <div className={styles.divSelection}>
            <div
              className="form-check"
              style={{ marginRight: '20px', display: 'inline-block' }}
              onClick={() => changeFilter(importFilter.all)}
              onKeyDown={() => changeFilter(importFilter.all)}
              role="presentation"
            >
              <input
                className={`${styles.formCheck} form-check-input`}
                type="radio"
                name="successFilter"
                id="flexRadioDefault1"
                checked={filter === importFilter.all}
              />
              <div className="form-check-label" htmlFor="flexRadioDefault1">
                Show all
              </div>
            </div>

            <div
              className="form-check"
              style={{ marginRight: '20px', display: 'inline-block' }}
              onClick={() => changeFilter(importFilter.Imported)}
              onKeyDown={() => changeFilter(importFilter.Imported)}
              role="presentation"
            >
              <input
                className={`${styles.formCheck} form-check-input`}
                type="radio"
                name="successFilter"
                id="flexRadioDefault2"
                checked={filter === importFilter.Imported}
              />
              <div className="form-check-label" htmlFor="flexRadioDefault2">
                Imported
              </div>
            </div>

            <div
              className="form-check"
              style={{ marginRight: '20px', display: 'inline-block' }}
              onClick={() => changeFilter(importFilter.NotImported)}
              onKeyDown={() => changeFilter(importFilter.NotImported)}
              role="presentation"
            >
              <input
                className={`${styles.formCheck} form-check-input`}
                type="radio"
                name="successFilter"
                id="flexRadioDefault3"
                checked={filter === importFilter.NotImported}
              />
              <div className="form-check-label" htmlFor="flexRadioDefault3">
                Not imported
              </div>
            </div>

            <img
              height="29px"
              style={{ float: 'right', cursor: 'pointer' }}
              title="Download results"
              src={downloadFileIcon}
              alt=""
              onClick={exportExcel}
            />
          </div>

          <div className={styles.divClose}>
            <Button
              text="Close"
              onClick={closeModal}
              style={{ marginRight: '0' }}
            />
          </div>
        </Modal.Body>
      )}

      {modalComponent === importModalComponents.loading && (
        <div className={styles.body} style={{ textAlign: 'center' }}>
          importing titles from {selectedFile.name}...
          <div className={styles.divLoading}>
            <p className={styles.pLoading}>
              <i
                className={`${styles.faCircleNotchSpin} fas fa-circle-notch fa-spin`}
              />
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
}
