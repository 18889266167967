import React, { useCallback } from 'react';
import Header from 'shared/components/header/Header';
import Button from 'shared/components/button/Button';
import Select from 'shared/components/select/Select';
import {
  GetChannelLookupAPI,
  GetBreakChannelTemplateAPI,
  ImportBreakPatternsAPI,
  SaveBreakChannelTemplateAPI,
  DeleteBreakChannelTemplateAPI
} from 'api';
import './BreakPatterns.css';
import { DataGrid } from 'op2mise-react-widgets';
import EditIcon from 'assets/icons/EditIcon.svg';
import { trashCanSmIcon } from 'assets';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import moment from 'moment';
import useNotification from 'store/NotificationStore';
import data from './break-patterns.json';
import BreakPatternEditor from './BreakPatternEditor';
import { getAllLastAccessed, saveAllLastAccessed } from 'utils';
import DeleteModalPrompt from './modals/DeleteModalPrompt';
import { OpDataGrid } from 'op2mise-react-widgets';
import { filterParams } from 'op2mise-react-widgets/OpDataGrid/utils';

const BreakPatterns = () => {
  const { success } = useNotification((state) => state);
  const [channelsList, setChannelsList] = React.useState([]);
  const [selectedChannel, setSelectedChannel] = React.useState(0);

  const [breakPatterns, setBreakPatterns] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [mode, setMode] = React.useState('view'); // view, edit, new
  const [versionName, setVersionName] = React.useState('');
  const [channelInfo, setChannelInfo] = React.useState({
    channelId: 0,
    channelName: null,
  });
  const [isDirty, setIsDirty] = React.useState(false);
  const [closeDeleteModal, setCloseDeleteModal] = React.useState(false);
  const [confirmModal, setConfirmModal] = React.useState(false);

  const getSession = () => {
    const session = getAllLastAccessed();
    const { organization, activeFunction, menuItem, channelId, channelName, tab } = session;
    if (channelId && channelName) {
      setSelectedChannel(channelId);
      setChannelInfo({ channelId, channelName })
    }
  }

  const getChannelsAPI = () => {
    GetChannelLookupAPI({
      onSuccess: (res) => {
        if (res.length) {
          setChannelsList(res);
          getSession();
        }
      },
      setLoader: setIsLoading,
    });
  };

  const handleDelete = (args) => {
    const { id } = args;
    DeleteBreakChannelTemplateAPI({
      queryParams: {
        id: id
      },
      onSuccess: (res) => {
        setIsDirty(true);
        success("Successfully deleted!");
        setConfirmModal(false);
        setCloseDeleteModal(false);
      }
    })
  }

  // Format created date to DD/MM/YYYY HH:mm
  const formattedDate = (params) =>
    params.data.createdDate !== null
      ? moment(params.data.createdDate).format('DD/MM/YYYY HH:mm')
      : '';

  const actionColumnCellRenderer = (params) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        height: '100%',
        width: '100%',
      }}
    >
      <img
        src={EditIcon}
        alt="Edit Pattern"
        title="Edit Pattern"
        onClick={() => {
          setMode('edit');
          setSelectedRow({
            ...params.data
          });
          setVersionName(params.data.version);
        }}
        style={{
          marginTop: '5px',
          cursor: 'pointer',
        }}
      />
      <img
        src={trashCanSmIcon}
        alt="Delete Pattern"
        title="Delete Pattern"
        onClick={() => {
          setConfirmModal(true)
          setSelectedRow(params.data);
        }}
        style={{
          marginTop: '5px',
          cursor: 'pointer',
        }}
      />
    </div>
  )
  // Column definitions for the data grid
  const columnDefinitions = React.useMemo(
    () => [
      {
        field: 'version',
        headerName: 'Pattern Name',
        sort: 'asc',
        flex: 1,
      },
      {
        field: 'createdDate',
        headerName: 'Date Created',
        flex: 1,
        valueFormatter: formattedDate,
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
      },
      {
        field: 'createdBy',
        headerName: 'Created by',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
      }, {
        field: 'default',
        headerName: 'Default',
        cellDataType: 'text',
        flex: 1,
        filterParams: {
          valueFormatter: (params) => {
            return params.value ? 'Yes' : 'No';
          },
        },
        valueFormatter: (params) => {
          return params.value ? 'Yes' : 'No';
        },
      },
      {
        flex: 1,
        field: 'action',
        minWidth: 100,
        cellDataType: 'action',
        cellRenderer: actionColumnCellRenderer,
      },
    ],
    []
  );

  const handleSelectChannel = (args) => {
    setSelectedChannel(args.channelId), setChannelInfo(args);

    saveAllLastAccessed({
      channelId: args.channelId,
      channelName: args.channelName,
    })
  }

  const getHighestId = (obj) => {
    let temp = 0;
    if (obj.length > 0) {
      obj.map((item) => {
        if (item.id > temp) {
          temp = item.id;
        }
      })
    }
    return temp;
  }

  const fetchBreakPatterns = (channelId) => {
    GetBreakChannelTemplateAPI({
      queryParams: { channelId },
      onSuccess: (res) => {
        // to pass id when new version is saved
        if (mode === 'new' && isDirty) setSelectedRow({
          ...selectedRow,
          id: getHighestId(res),
          status: res.find((item) => item.id === getHighestId(res))?.status,
        })
        else setSelectedRow({
          ...selectedRow,
          id: res.find((item) => item.id === selectedRow.id)?.id,
          status: res.find((item) => item.id === selectedRow.id)?.status,
        });
        setIsDirty(false)
        setBreakPatterns(res);
      },
      setLoader: setIsLoading,
    });
  };

  const handleImportBreakTypes = async (file) => {
    const { channelId } = channelInfo;
    const uploadResults = await ImportBreakPatternsAPI({
      channelId,
      file,
    });
    return uploadResults;
  };

  const props = React.useMemo(
    () => ({
      mode: mode,
      setMode: (params) => setMode(params),
      onClose: () => {
        setMode('view');
      },
      onImport: handleImportBreakTypes,
      selectedRow: selectedRow,
      setSelectedRow: setSelectedRow,
      channelInfo: channelInfo,
      setIsDirty: (params) => setIsDirty(params),
      isDirty: isDirty,
    }),
    [mode, selectedRow, selectedChannel]
  );

  // Returns all fields from columnDefinition except the action column
  const headerFields = columnDefinitions
    .filter((column) => column.field !== 'action')
    .map((column) => column.field);

  React.useEffect(() => {
    getChannelsAPI();
    setSelectedRow({});
  }, []);

  React.useEffect(() => {
    const { channelId } = channelInfo;
    if (channelId || isDirty) {
      fetchBreakPatterns(channelId);
    }
  }, [channelInfo, isDirty]);

  return (
    <div>
      <Header
        title={
          <div>
            <span>Break Patterns</span>
          </div>
        }
        childComponent={
          <div className="d-flex align-items-center gap-4">
            {/* Channels Selection/Dropdown */}
            {/* {mode === 'view' || mode === 'edit' ? ( */}
            <div className="break-banner-selector">
              <span className="break-banner-channel-label">Channel:</span>
              <div>
                {/** Re-locate this re-usable component to `op2mise-react-widgets */}
                <Select
                  text={
                    channelsList.find(
                      (channel) => channel.channelId === selectedChannel
                    )?.channelName ?? 'Please select a channel'
                  }
                  list={channelsList}
                  onSelect={(args) => handleSelectChannel(args)}
                  width="200px"
                  disabled={mode === 'edit'}
                />
              </div>
            </div>
            {/* ) : null} */}

            {/* Additional CTAs here */}
            <div
              style={{
                width: 'auto',
              }}
            >
              <Button
                text="New Pattern"
                onClick={() => {
                  setMode('new');
                  setSelectedRow({
                    id: null,
                    version: '',
                    status: 'Inactive',
                    default: false,
                    contentLevel:
                    {
                      eventType: null,
                      eventName: null,
                      eventDurationFrom: null,
                      eventDurationTo: null,
                      timeOfDayFrom: null,
                      timeOfDayTo: null,
                      periodFrom: null,
                      periodTo: null,
                    }
                  });
                }}
                hidden={mode !== 'view'}
                disabled={channelsList.length === 0 || selectedChannel === 0}
              />
            </div>
          </div>
        }
      />
      <div>
        {isLoading ? (
          <div style={{ height: 'calc(100vh - 123px)' }}>
            <BootstrapSpinner pushTop="-9px" />
          </div>
        ) : channelsList.length ? (
          mode === 'view' ? (
            <OpDataGrid
              columns={columnDefinitions}
              customVerbiage="No patterns found"
              enableMultipleRowSelection={false}
              gridHeightBuffer={177}
              gridName="channelSetup-breakPatterns"
              resizable
              rows={breakPatterns}
              filter
              sortable
              sharedColumnProperties={{
                cellStyle: {
                  fields: headerFields,
                  value: { textAlign: 'left' },
                },
              }}
              showAlternativeRowHighlight
              suppressRowClickSelection={true}
            />
          ) : (
            <BreakPatternEditor {...props} />
          )
        ) : null}
      </div>
      <DeleteModalPrompt
        show={confirmModal}
        onHide={() => { setConfirmModal(false) }}
        onProceed={() => { handleDelete(selectedRow) }}
        message={null}
      />
    </div>
  );
};

export default BreakPatterns;
