import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

const useAccountStore = createWithEqualityFn((set) => ({
  user: {},
  setUser: (data) => set(() => ({ user: data })),
  isUserAuthenticated: false,
  setIsUserAuthenticated: (data) => set(() => ({ isUserAuthenticated: data })),
  organisationAdmin: (value) => {
    return value.roleId === 2;
  },
}));

const useStore = (callback) => useAccountStore(callback, shallow);

export default useStore;
