export const Constants = {
    events: [
        { id: 1, name: 'Program' },
        { id: 2, name: 'Series' },
        { id: 3, name: 'Season' },
        { id: 4, name: 'Title Group' },
        { id: 5, name: 'Clear selection' }
    ],


}