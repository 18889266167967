import React, { useState, useRef, useEffect } from 'react';
import ImportFileModalComponent from 'components/schedule/react-scheduler/forms/import/ImportFileModalComponent';
import ImportLoadingModalComponent from 'components/schedule/react-scheduler/forms/import/ImportLoadingModalComponent';
import ImportConfirmationModalComponent from 'components/channel-profiles/business-rules/modals/ImportConfirmationModalComponent';
import ImportResultModalComponent from './ImportResultModalComponent';
import styles from '../../schedule/react-scheduler/forms/import/ImportSlots.module.css';
import { guid } from '@progress/kendo-react-common';

export function ImportComponent({
  closeModal,
  setScheduleData,
  scheduledata = [],
  clockStart = 0,
  scheduleInfo,
  schedulerData,
  setIsDirty,
  sampleTemplate,
  handleOnImportApi,
  importResultColumns,
  importResultFileTitle,
  dataExists,
  dataModel,
  fileError,
  setSchedules,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [headerData, setHeaderData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedList, setUploadedList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [uploadedSlots, setUploadedSlots] = useState([]);
  const { channelId } = { ...scheduleInfo };
  const { schedules, period } = { ...schedulerData };
  const [isLoading, setIsLoading] = useState(false);
  const loadingHeaderComponent = (
    <div className={styles.divHeader}>
      Importing and validating slots from{' '}
      <b>{selectedFile && selectedFile.name}</b>
    </div>
  );

  const removeImportAndResultFields = (data) => {
    let temp = data;
    let removedFailedImports = temp.filter((schedule) => schedule.imported)

    const cleanedData = removedFailedImports.filter((schedule) => {
      schedule = Object.keys(schedule).reduce((object, key) => {
        if (key !== 'imported' && key !== 'result') {
          object[key] = schedule[key];
        }
        return object;
      }, {});
      return schedule;
    })
    return cleanedData;
  }

  const uploadFile = async (file, fileExtension, overwrite) => {
    setIsLoading(true)
    try {
      const response = await handleOnImportApi(
        channelId,
        file,
      );
      if (response.status === 200) {
        if (response.data[dataExists] && !overwrite) {
          setIsDirty(false);
          setUploadedList();
          setTimeout(() => {
            setIsLoading(false);
          }, [1200])
          setConfirmModal(true);
        } else {
          setIsDirty(true);
          setUploadedList(response.data[dataModel].map((o) => ({ id: guid(), ...o })));
          setConfirmModal(false);
          setTimeout(() => {
            setSchedules(removeImportAndResultFields(response.data[dataModel]));
            setUploadSuccess(true);
            setIsLoading(false);
          }, [3400])
        }
      } else {
        danger("Error occurred while importing file.")
      }
    } catch (error) {
      if (error.message.includes("400")) {
        setErrorMessage(
          "Invalid file format. Please use files in the suggested format above (see sample template)"
        );
      } else if (error.message.includes("500")) {
        setErrorMessage("Error uploading file. Please try again later");
        console.error(error);
      }
      setConfirmModal(false);
      setIsDirty(false);
      setUploadSuccess(false);
      setIsLoading(false);

    }

  };

  const handleOnImportFile = () => {
    const acceptableFiles = ['xlsx', 'xls', 'csv'];
    const fileExtension = selectedFile.name.split('.').pop();
    if (acceptableFiles.includes(fileExtension)) {
      if (scheduledata.length > 0) setConfirmModal(true);
      else uploadFile(selectedFile, fileExtension, false);
    } else {
      setErrorMessage(
        'Uploaded file type is not supported. Please use files in the following format (xlsx, csv)'
      );
    }
  };

  const handleOverwrite = () => {
    const fileExtension = selectedFile.name.split('.').pop();
    uploadFile(selectedFile, fileExtension, true);
  };

  return isLoading ? (
    <ImportLoadingModalComponent headerComponent={loadingHeaderComponent} />
  ) : uploadSuccess ? (
    <ImportResultModalComponent
      uploadedList={uploadedList}
      closeModal={closeModal}
      scheduleInfo={scheduleInfo}
      importResultColumns={importResultColumns}
      importResultFileTitle={importResultFileTitle}
    />
  ) : confirmModal ? (
    <ImportConfirmationModalComponent
      show={confirmModal}
      onHide={closeModal}
      onProceed={handleOverwrite}
      message="A list already exists for this channel. Importing overwrites all existing data for this channel."
    />
  ) : (
    <ImportFileModalComponent
      closeModal={closeModal}
      selectedFile={selectedFile}
      setSelectedFile={(e) => {
        setSelectedFile(e);
        setErrorMessage('');
      }}
      handleOnImportFile={handleOnImportFile}
      errorMessage={errorMessage}
      setErrorMessage={(e) => setErrorMessage(e)}
      sampleTemplate={sampleTemplate}
    />
  );
}
