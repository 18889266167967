import { defaultAvatar } from 'assets';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import authService from 'core/auth.service';
import useStore from 'store/AccountStore';

const ProfileMenu = () => {
    const navigate = useNavigate();
    const userProfileWrapperRef = useRef();
    const userDetailsRef = useRef();
    const {
        user,
        setUser,
        setIsUserAuthenticated,
    } = useStore((state) => state);

    const [showMenu, setShowMenu] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleLogout = () => {
        setUser({});
        authService.logout();
        setIsUserAuthenticated(false);
    };

    const handleShowTooltip = (event) => {
        const hasOverflow = event.target.scrollWidth > event.target.clientWidth;
        setShowTooltip(hasOverflow);
    };

    const openMenu = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const backdropClicked = (e) => {
            if (!userProfileWrapperRef.current?.contains(e.target) && !userDetailsRef.current?.contains(e.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', backdropClicked);
        return () => document.removeEventListener('mousedown', backdropClicked);
    });

    return (
        <div className="position-relative">
            <div title="Profile" ref={userProfileWrapperRef} className={styles.userProfileWrapper} onMouseUp={openMenu}>
                <img className={styles.userProfile} src={user?.profilePic || defaultAvatar} alt="" loading="lazy" />
                <div class={styles.userProfileShadow}></div>
            </div>

            {showMenu && (
                <div ref={userDetailsRef}>
                    <div className={styles.triangleShape}></div>

                    <div className={styles.userDetailsWrapper}>
                        <div className="text-center position-relative">
                            <p className={styles.username} title={showTooltip ? username : ''} onMouseEnter={handleShowTooltip}>{`${user?.firstName} ${user?.lastName}`}</p>
                            <p className={styles.title} title={showTooltip ? jobTitle : ''} onMouseEnter={handleShowTooltip}>{user?.jobTitle}</p>
                        </div>

                        <div className={styles.profileLink} onClick={() => navigate('/profile')}>Profile</div>

                        <div className={styles.divider}></div>

                        <div className={styles.signoutLink} onClick={handleLogout}>
                            Sign Out
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default ProfileMenu;