import moment from "moment";

export const filterParams = { 
  comparator: (filterLocalDateAtMidnight, cellValue) => { 
    
    const possibleFormats = ["DD/MM/YYYY HH:mm", "DD/MM/YYYY", "MM/DD/YYYY", "MM/DD/YYYY HH:mm", "YYYY-MM-DD", "YYYY-MM-DDTHH:mm:ss"];
   
    // Check if cellValue exists and is valid
    if (!cellValue) return -1; // Treat empty or null values as "not equal"

    // Convert cellValue to an ISO string
     const isoDate = moment(cellValue, possibleFormats).toISOString();

    // Parse cellValue to a Date object, and handle cases where it may have time
    let cellDate = new Date(isoDate);

    // If cellDate is invalid, return -1
    if (isNaN(cellDate)) return -1;

    // Normalize both dates to midnight (removes time component)
    cellDate.setHours(0, 0, 0, 0);
    const filterDate = new Date(filterLocalDateAtMidnight); // Already normalized to midnight

    // Compare the normalized dates
    if (cellDate.getTime() === filterDate.getTime()) {
      return 0; // Equals
    }
    if (cellDate.getTime() < filterDate.getTime()) {
      return -1; // Less than
    }
    return 1; // Greater than
  }
};
