import * as React from 'react';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';

// Local imports
import LinkIcon from 'assets/icons/channel-architecture-linked-slot.svg';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import Button from 'shared/components/button/Button';

// Custom hooks
import useStore from 'store/AccountStore';

// Third-party libraries
import { Scheduler } from 'op2mise-react-widgets';
import { OpScheduler } from 'op2mise-react-widgets';

// Utility functions
import {
  formatDateWithMoment,
  addDaysToDate,
} from 'utils';

// API imports
import {
  GetChannelArchitectureAPI,
  ImportChannelArchitectureAPI,
  ExportChannelArchitectureAPI,
  GetSeriesAPI,
  GetTitleGroupLookupAPI,
} from 'api';

// Modals
import { ImportComponent } from './modals/ImportComponent';
import ModifyBlock from './modals/ModifyBlock';
import DuplicateComponent from './modals/DuplicateComponent';

// Helper functions
import { Constants } from './helper/constants';
import { getMondayZeroToSixSchedules } from './helper/getMondayZeroToSixSchedules';
import {
  getBlockName,
  reconstructBlockName
} from './utils/architecture.utils'

// Constants
import { ActionTypes } from './ChannelArchitecture.d';

// FullCalendar import
import { padStart } from '@fullcalendar/react';

function CAProgrammmes({
  calculateDates,
  channelInfo,
  dispatch,
  endDate,
  handleSaveChannelArchitecture,
  isDirty,
  numberOfWeeks,
  schedulerProps,
  setIsDirty,
  setNumberOfWeeks,
  startDate,
  schedules,
  setAllEvents
}) {
  const { formTypes, weekdays, singleQuery } = Constants;
  const { user } = useStore((state) => state);
  const instance = new Internationalization();
  const blockData = React.useRef({});
  const [openForm, setOpenForm] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { channelId, channelName } = channelInfo;
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedSlot, setSelectedSlot] = React.useState({});
  const [modalType, setModalType] = React.useState('');
  const [titleGroupList, setTitleGroupList] = React.useState([]);
  const [seriesList, setSeriesList] = React.useState([]);
  // const [referenceList, setReferenceList] = React.useState([]);
  const [isClockTypeBroadcast, setClockTypeBroadcast] = React.useState(user?.clockType === 'Broadcast');

  const updateAllItem = (payload, saveChange) => {
    dispatch({
      actionType: ActionTypes.UPDATEALL,
      payload,
      saveChange
    });
  };

  const getReferenceList = () => {
    let references = []
    if (blockData.current && blockData.current.EventGetAll) {
      const weekOneSchedules = blockData.current.EventGetAll().filter((event) => event.Week < 2)
      const sortedBlocks = weekOneSchedules.sort((a, b) => {
        if (a.DayOfWeek > b.DayOfWeek) return 1;
        if (a.DayOfWeek < b.DayOfWeek) return -1;
        if (a.StartTime > b.StartTime) return 1;
        if (a.StartTime < b.StartTime) return -1;
        return 0;
      });
      references = sortedBlocks.map((value) => {
        const linked = blockData.current.EventGetAll().find(
          (x) => x.BlockReference == value.BlockName
        )
          ? ', Linked'
          : '';
        return {
          id: value.Id,
          name: `${value.BlockName} (${value.Type}${linked})`,
          blockName: value.BlockName,
          linked: linked !== '',
          series: getSeriesInfo(value.SeriesName, +value.SeasonID),
          titleGroup: getTitleGroupInfo(value.TitleGroupName),
        };
      });
    } else
      if (Object.keys(schedules).length !== 0) {
        const weekOneSchedules = schedules.programmes.filter((event) => event.week < 2)
        const sortedBlocks = weekOneSchedules.sort((a, b) => {
          if (a.dayOfWeek > b.dayOfWeek) return 1;
          if (a.dayOfWeek < b.dayOfWeek) return -1;
          if (a.startTime > b.startTime) return 1;
          if (a.startTime < b.startTime) return -1;
          return 0;
        });
        references = sortedBlocks.map((value) => {
          const linked = sortedBlocks.find(
            (x) => x.blockReference == value.blockName
          )
            ? ', Linked'
            : '';
          return {
            id: value.id,
            name: `${value.blockName} (${value.type}${linked})`,
            blockName: value.blockName,
            linked: linked !== '',
            series: getSeriesInfo(value.seriesName, +value.seasonID),
            titleGroup: getTitleGroupInfo(value.titleGroupName),
          };
        });
      }
    return references;
  }

  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate(x, y) from date.utils
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(new Date())} - ${formatDateWithMoment(
    tomorrow
  )}`;

  const getEndDate = () => {
    return numberOfWeeks > 1
      ? addDaysToDate(startDate, numberOfWeeks * 7)
      : endDate;
  };

  const dateHeaderTemplate = React.useCallback(
    (args) => {
      const day = instance.formatDate(args.date, { skeleton: 'E' });
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="e-header-day"
            style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
          >{`${day}`}</div>
        </div>
      );
    },
    [startDate, endDate]
  );

  const eventFields = (args) => ({
    Id: args?.id,
    Subject: args?.type ?? args?.seriesName,
    StartTime: calculateDates(
      args?.dayOfWeek,
      args?.week,
      args?.startTime,
      args?.endTime,
      { startDate, endDate }
    )['startTime'], // Field required: Always convert to new Date(...)
    EndTime: calculateDates(
      args?.dayOfWeek,
      args?.week,
      args?.startTime,
      args?.endTime,
      { startDate, endDate }
    )['endTime'], // Field required: Always convert to new Date(...)
    IsLinked: args?.link,
    IsSequential: args?.sequential,
    BlockReference: args?.blockReference,
    TitleGroupName: args?.titleGroupName,
    SeriesName: args?.seriesName,
    BlockName: args?.blockName,
    Genre: args?.genre,
    Week: args?.week ?? 1,
    DayOfWeek: args?.dayOfWeek,
    MaxCount: args?.maxCount,
    AvailableDuration: args?.availableDuration,
    Layout: args?.layout,
    Type: args?.type,
    SeasonID: args?.seasonID,
    SeriesID: args?.seriesID ? args?.seriesID : args.seriesID,
    TitleGroupID: args?.titleGroupID,
  });

  const eventTemplate = React.useCallback((args) => {
    const {
      State,
      StartTime,
      EndTime,
      Subject,
      IsSequential,
      IsLinked,
      BlockReference,
      TitleGroupName,
      SeriesName,
      Type,
    } = args;
    /** Converts the date value and extracts the time */
    const getTimeString = (value) => {
      return instance.formatDate(value, {
        skeleton: 'hm',
        format: State.timeFormat,
      });
    };

    const getScheduleBlockSubject = () => {
      const subjects = {
        TitleGroup: TitleGroupName,
        Series: SeriesName,
      };
      if (Type === 'Repeat') {
        return TitleGroupName ?? SeriesName ?? 'Repeat';
      }
      return subjects[Subject] ?? Subject;
    };

    return (
      <>
        <div className="op-event-subject">
          {getScheduleBlockSubject()}
        </div>
        <div className="op-event-content">
          <div className="op-event-schedule">{`${getTimeString(
            StartTime
          )} - ${getTimeString(EndTime)}`}</div>

          {(IsLinked || IsSequential) && (
            <div
              style={{
                position: 'absolute',
                bottom: '1.2%',
                marginBottom: '4px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {/* {IsSequential && ( <img className='icon-link-sequential' src={SequentialIcon} alt='sequential-icon' width={16} height={16}/> )} */}
              {IsLinked && (
                <div
                  style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                >
                  <img
                    className="icon-link-sequential"
                    src={LinkIcon}
                    alt="link-icon"
                    width={16}
                    height={16}
                  />
                  <span
                    style={{
                      color: 'rgba(250, 250, 250, 0.5)',
                      marginLeft: '1px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '80%',
                    }}
                  >{`${BlockReference} (${Subject})`}</span>
                </div>
              )}
            </div>
          )}</div>
      </>
    );
  }, []);

  const getMaxWeek = (data) => {
    let maxWeek = 0;
    data.map((programme) => {
      if (programme.week > maxWeek) {
        maxWeek = programme.week;
      }
    });
    setNumberOfWeeks(maxWeek);
  };

  const getSelectedWeek = (e) => {
    if (!e.startTime) return 1;
    const msDay = 24 * 60 * 60 * 1000; // milliseconds per day
    const totalDays = Math.floor(
      (new Date(e.startTime) - new Date(startDate)) / msDay
    );
    const week = Math.floor(totalDays / 7) + 1;
    return week;
  };

  React.useEffect(() => {
    // Close the dialog when the component is unmounted
    setIsDialogOpen(false);
    blockData.current = {};
  }, []);

  const handleDrag = (args) => {
    const day = instance.formatDate(args.data.StartTime, { skeleton: 'E' });

    args.schedulerActions.EventChange({
      availableDuration: args.data?.AvailableDuration || 0,
      blockName: getBlockName(weekdays[day], moment(args.data.StartTime, "HH:mm:ss").format("HH:mm:ss")),
      blockReference: args.data?.BlockReference,
      channelID: channelInfo.channelId,
      dayOfWeek: weekdays[day],
      endTime: moment(args.data.EndTime, "HH:mm:ss").format("HH:mm:ss"),
      startTime: moment(args.data.StartTime, "HH:mm:ss").format("HH:mm:ss"),
      genre: args.data?.Genre || null,
      layout: args.data?.Layout,
      link: args.data?.Link ?? false,
      maxCount: args.data?.MaxCount || 0,
      seriesID: args.data?.SeriesId || null,
      seriesName: args.data?.SeriesName || null,
      sequential: false,
      titleGroupID: args.data?.TitleGroupID || null,
      titleGroupName: args.data.TitleGroupName || null,
      type: args.data?.Type || 'Movies',
      week: args.data?.Week,
      id: isNaN(args.data?.Id) ? 0 : args.data.Id,
    })
    const all = args.schedulerActions.EventGetAll();
    setAllEvents(all)
    setIsDirty(true);
  };

  // React.useEffect(() => {
  //   Object.keys(schedules).length !== 0 && getReferenceList();
  // }, [schedules]);

  const customFooter = React.useCallback(({ events, schedulerActions }) => {
    return (
      <div style={{ marginTop: '15px', float: 'right' }}>
        <Button
          text="Save"
          style={{ marginLeft: '10px' }}
          onClick={() => {
            handleSaveChannelArchitecture(schedulerActions.EventGetAll())
            schedulerActions.ResetEventHistory();
          }}
          disabled={!isDirty}
        />
      </div>
    );
  }, [isDirty]);

  const handleDuplicatedSlot = (args) => {
    if (args.data.Id) {
      setSelectedSlot({
        ...args.data,
        EventCreate: args.schedulerActions.EventCreate,
        EventGetAll: args.schedulerActions.EventGetAll,
        EventConflictValidator: args.schedulerActions.EventConflictValidator,
      });
      setOpenForm(formTypes.paste);
    }
  };

  const handleOnExport = () => {
    if (schedules.programmes.length > 0) {
      ExportChannelArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (res) => {
          const { organisationName } = user;
          let exportDate = new Date();
          const exportedDateString =
            padStart(exportDate.getDate(), 2).toString() +
            padStart(exportDate.getMonth() + 1, 2).toString() +
            exportDate.getFullYear().toString();
          const fileName = `${organisationName}_op2mise_channel_architecture_programme_export_${channelName}_${exportedDateString}.xlsx`;
          const blob = new Blob([res], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, fileName);
        },
      });
    }
  }
  const handleOnImport = (args) => {
    if (args) {
      blockData.current = {
        ...args.data,
        EventCreate: args.schedulerActions.EventCreate,
        EventGetAll: args.schedulerActions.EventGetAll,
        EventImport: args.schedulerActions.EventImport,
      }
      setOpenForm(formTypes.import);
    }
  }

  const isBlockCloned = (args) => {
    let selected = args.data;
    let allEvents = args.schedulerActions.EventGetAll();
    let cloneInfo = {
      isCloned: false,
      CloneId: null
    }

    if (args.data.Week === 2 && args.data.DayOfWeek === 1) {
      selected = allEvents.find((event) =>
        event.Week === 1
        && event.DayOfWeek === args.data.DayOfWeek
        && args.data.BlockName === event.BlockName
      )
      cloneInfo.isCloned = true;
      cloneInfo.CloneId = args.data.Id;
    }

    if (args.data.Week === 1 && args.data.DayOfWeek === 1) {
      let hasClone = allEvents.find((event) =>
        event.Week === 2
        && event.DayOfWeek === args.data.DayOfWeek
        && args.data.BlockName === event.BlockName);
      if (hasClone) {
        cloneInfo.isCloned = true;
        cloneInfo.CloneId = hasClone.Id;
      }

    }

    return { selected, ...cloneInfo };
  }
  const handleOnSlotEditSeries = (args) => {
    setIsDialogOpen(true);
    setModalType(ActionTypes.MULTIPLEUPDATE);
    const { selected, isCloned, CloneId } = isBlockCloned(args)
    blockData.current = {
      ...selected,
      EventDelete: args.schedulerActions.EventDelete,
      EventChange: args.schedulerActions.EventChange,
      EventGetAll: args.schedulerActions.EventGetAll,
      EventConflictValidator: args.schedulerActions.EventConflictValidator,
      ...(isCloned && { CloneId })
    }
  }
  const handleOnSlotModified = (args) => {
    setIsDialogOpen(true);
    setModalType(ActionTypes.UPDATE);
    const { selected, isCloned, CloneId } = isBlockCloned(args)
    blockData.current = {
      ...selected,
      EventDelete: args.schedulerActions.EventDelete,
      EventChange: args.schedulerActions.EventChange,
      EventGetAll: args.schedulerActions.EventGetAll,
      EventConflictValidator: args.schedulerActions.EventConflictValidator,
      ...(isCloned && { CloneId })
    }
  }
  const handleOnEventCreate = (args) => {
    blockData.current = {
      EndTime: args.endTime,
      StartTime: args.startTime,
      Type: 'Movies',
      Layout: 'Sequential',
      Week: getSelectedWeek(args),
      EventCreate: args.schedulerActions.EventCreate,
      EventGetAll: args.schedulerActions.EventGetAll,
      EventConflictValidator: args.schedulerActions.EventConflictValidator,
    };
    setModalType(ActionTypes.CREATE);
    setIsDialogOpen(true);
  }

  const getSeriesInfo = (seriesName, seasonId) => {
    const found = seriesList.find((s) => seriesName === s.name)
    const seasonData = found?.seasons.find((season) => season.id === seasonId)
    return found && {
      seriesID: found?.id,
      seriesName: found?.name,
      seasonID: seasonData?.id,
      seasonName: seasonData?.name
    }
  }

  const getTitleGroupInfo = (titleGroupName) => {
    const found = titleGroupList.find((t) => titleGroupName === t.name)
    return found;
  }

  const lowerCaseNormalizedData = (all) => {
    return all.map((schedule) => {
      return {
        availableDuration: schedule?.AvailableDuration || 0,
        blockName: getBlockName(schedule.DayOfWeek, schedule.StartTime),
        blockReference: schedule?.BlockReference,
        dayOfWeek: schedule.DayOfWeek,
        endTime: moment(schedule.EndTime, "HH:mm:ss").format("HH:mm:ss"),
        genre: schedule?.Genre || null,
        layout: schedule?.Layout,
        link: schedule?.IsLinked ? schedule.IsLinked : false,
        maxCount: schedule?.MaxCount || 0,
        seasonID: schedule?.SeasonID || null,
        seriesID: schedule?.SeriesId || null,
        seriesName: schedule?.SeriesName || null,
        sequential: false,
        startTime: moment(schedule.StartTime, "HH:mm:ss").format("HH:mm:ss"),
        titleGroupID: schedule?.TitleGroupID || null,
        titleGroupName: schedule.TitleGroupName || null,
        type: schedule?.Type || 'Movies',
        week: schedule.Week,
        id: schedule.Id,
      }
    })
  }

  const findRelatedBlocks = (selected, titleInfo, allEvents) => {
    return allEvents.filter((block) => {
      if (block.id !== selected.Id) {
        if (selected.Type === 'Series') {
          if (titleInfo?.seasonID) {
            if (block.seriesName === titleInfo?.seriesName && block.seasonId === titleInfo.seasonID) {
              return block;
            }
          } else {
            if (block.seriesName === titleInfo?.seriesName) {
              return block;
            }
          }
        }
        if (selected.Type === 'TitleGroup') {
          if (block.titleGroupName === titleInfo?.name) {
            return block;
          }
        }
        if (selected.Type === 'Repeat') {
          if ((block.seriesName && titleInfo?.seriesName === block.seriesName) || (block.seasonId && block.seasonId === titleInfo?.seasonID)) {
            return block;
          }
          if ((block.titleGroupName && titleInfo?.name === block.titleGroupName)) {
            return block;
          }
        }
      }
    })
  }

  const updateBlockReferences = (list, action, allEvents) => {
    let toUpdate = []
    if (list.length) {
      const toDeleteBlockNames = list.map((o) => (o.blockName))
      const foundBlocks = allEvents.filter((block) => {
        if (toDeleteBlockNames.includes(block.blockReference)) {
          return block;
        }
      })

      if (foundBlocks.length) {
        foundBlocks.forEach((block) => {
          if (block.type === 'Repeat') {
            toUpdate.push({
              ...block,
              blockReference: '',
              link: true,
            })
          } else {
            toUpdate.push({
              ...block,
              blockReference: '',
              link: false
            })
          }
        })
      }
    }
    if (toUpdate.length) {
      action.EventChange(toUpdate)
    }
  }

  const toCamelCase = (obj) => {
    let newObj = []
    for (const [key, value] of Object.entries(obj)) {
      const firstLetterLowerCased = key.charAt(0).toLowerCase();
      const camelCasedKey = key.replace(key.charAt(0), firstLetterLowerCased);
      newObj[camelCasedKey] = value;
    }
    return newObj;
  }

  const handleDelete = (args) => {
    const data = toCamelCase(args.data)
    const startTime = moment(args.data.StartTime).format('HH:mm:ss');
    const endTime = moment(args.data.EndTime).format('HH:mm:ss');
    const type = args.data.Type;
    let found = []
    const allEvents = lowerCaseNormalizedData(args.schedulerActions.EventGetAll());
    if (!singleQuery.includes(args.data.Type)) {
      if (type === 'Series' || type === 'Repeat') {
        const seriesInfo = getSeriesInfo(args.data.SeriesName, args.data.SeasonId);
        if (seriesInfo) {
          found = findRelatedBlocks(args.data, seriesInfo, allEvents)
        }
      }
      if (type === 'TitleGroup' || type === 'Repeat') {
        const titleGroupInfo = getTitleGroupInfo(args.data.TitleGroupName);
        if (titleGroupInfo) {
          found = findRelatedBlocks(args.data, titleGroupInfo, allEvents)
        }
      }
    }

    const { selected, isCloned, CloneId } = isBlockCloned(args)
    if (isClockTypeBroadcast) {
      if (isCloned) {
        if (args.data.Id === CloneId) {
          args.schedulerActions.EventDelete(toCamelCase([{
            ...selected,
            StartTime: startTime,
            EndTime: endTime,
          }]))
        } else {
          const week2Clone = args.schedulerActions.EventGetAll().find((o) => CloneId === o.Id);
          if (week2Clone) args.schedulerActions.EventDelete(toCamelCase({
            ...args.data,
            StartTime: startTime,
            EndTime: endTime,
            Id: week2Clone.Id,
            Week: week2Clone.Week
          }))
        }
      }
    }
    // update all blocks to remove link reference for found blocks to be deleted
    if (found.length) {
      updateBlockReferences([...found, { ...args.data, blockName: args.data.BlockName }], args.schedulerActions, allEvents)
      args.schedulerActions.EventDelete([
        ...found, {
          ...data,
          startTime: startTime,
          endTime: endTime
        }])
    } else {
      updateBlockReferences([{
        ...args.data,
        blockName: args.data.BlockName
      }], args.schedulerActions, allEvents)
      args.schedulerActions.EventDelete([{
        ...data,
        startTime: startTime,
        endTime: endTime
      }])
    }
    setIsDirty(true);
  }

  const handleRepeat = (args) => {
    setIsDialogOpen(true);
    setModalType(ActionTypes.REPEAT);
    blockData.current = {
      ...args.data,
      EventDelete: args.schedulerActions.EventDelete,
      EventChange: args.schedulerActions.EventChange,
      EventCreate: args.schedulerActions.EventCreate,
      EventGetAll: args.schedulerActions.EventGetAll,
      EventConflictValidator: args.schedulerActions.EventConflictValidator,
    }
  }

  const handleOnDuplicateResult = (args) => {
    if (args && args.length) {
      selectedSlot.EventCreate(getMondayZeroToSixSchedules(
        args,
        { startDate, endDate },
        isClockTypeBroadcast))
      setAllEvents(selectedSlot.EventGetAll())
    } else {
      if (args?.id) {
        selectedSlot.EventCreate(getMondayZeroToSixSchedules(
          [args],
          { startDate, endDate },
          isClockTypeBroadcast))
        setAllEvents(selectedSlot.EventGetAll())
      }
    }
    setIsDirty(true);
  }

  React.useEffect(() => {
    if (channelId || isDirty) {
      GetChannelArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          updateAllItem({
            programmes: getMondayZeroToSixSchedules(
              response,
              { startDate, endDate },
              isClockTypeBroadcast).map((e) => ({
                ...e,
                blockName: reconstructBlockName(e.blockName),
                blockReference: reconstructBlockName(e.blockReference)
              }))
          });
          getMaxWeek(response);
          setIsDirty(false);
        },
        setLoader: setIsLoading,
      });
      GetSeriesAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          setSeriesList(response.series);
        },
        setLoader: setIsLoading,
      });
      GetTitleGroupLookupAPI({
        onSuccess: (response) => {
          setTitleGroupList(response);
        },
        setLoader: setIsLoading,
      });
    }
  }, [channelId, isClockTypeBroadcast]);

  const blockProps = React.useMemo(() => ({
    allEvents: schedules.programmes?.length ? { programmes: schedules.programmes?.filter((schedule) => schedule.week === 1 || schedule.week === 0) } : { programmes: [] },
    setIsDialogOpen,
    blockData: blockData.current,
    numberOfWeeks: 1,
    modalType,
    titleGroupList,
    seriesList,
    referenceList: getReferenceList(),
    isBroadcast: isClockTypeBroadcast,
    setIsDirty,
    setAllEvents: (args) => setAllEvents(args),
    findRelatedBlocks,
    getSeriesInfo,
    getTitleGroupInfo,
    setModalType,
    updateBlockReferences
  }), [
    schedules,
    blockData.current,
    isClockTypeBroadcast,
    modalType,
    titleGroupList,
    seriesList
  ]);

  const duplicateProps = React.useMemo(() => ({
    allEvents: schedules,
    startDate: startDate,
    endDate: endDate,
    setNumberOfWeeks: setNumberOfWeeks,
    channelId: channelId,
    selectedSlot: selectedSlot,
    onClose: () => setOpenForm(''),
    onPaste: handleOnDuplicateResult,
    numberOfWeeks: 1, // Used for the Recurrence Pattern Component
    referenceList: getReferenceList(),
    seriesList,
    titleGroupList,
  }), [
    schedules,
    channelId,
    selectedSlot,
    startDate,
    endDate
  ])

  React.useEffect(() => {
    // Change between Standard and Broadcast clocktype
    setClockTypeBroadcast(user?.clockType === 'Broadcast')
  }, [user]);

  React.useEffect(() => {
    if (numberOfWeeks === 0) setNumberOfWeeks(1);
  }, [numberOfWeeks]);

  return (
    <>
      {isDialogOpen && <ModifyBlock {...blockProps} />}
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm(false)}
          setIsDirty={setIsDirty}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            schedules: schedules.programmes,
            period: { startTime: startDate, endTime: getEndDate() },
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="programmeImportTemplate"
          handleOnImportApi={ImportChannelArchitectureAPI}
          dataExists='channelArchitectureExist'
          dataModel='channelArchitectureModel'
          fileError='channelArchitectureError'
          importResultColumns={[
            {
              field: 'dayOfWeek',
              headerText: 'Day of Week',
            },
            {
              field: 'startTime',
              headerText: 'Start Time',
            },
            {
              field: 'endTime',
              headerText: 'End Time',
            },
            {
              field: 'type',
              headerText: 'Type',
            },
            {
              field: 'genre',
              headerText: 'Genre',
            },
          ]}
          importResultFileTitle="programme_import_result"
          setSchedules={(data) => {
            if (data.length > 0) {
              let importedData = getMondayZeroToSixSchedules(
                data.map((o) => ({ seasonID: o.seasonNo, ...o, week: 1, })),
                { startDate, endDate },
                isClockTypeBroadcast)
              blockData.current.EventImport(importedData)
              setAllEvents(blockData.current.EventGetAll())

              setIsLoading(false);
            }
          }}
        />
      )}
      {openForm === formTypes.paste && (
        <DuplicateComponent
          {...duplicateProps}
        />
      )}
      {channelId === 0 && !channelName ? null :
        isLoading ? (
          <div style={{ height: 'calc(100vh - 120px)' }}>
            <BootstrapSpinner />
          </div>
        ) : (
          <OpScheduler {...schedulerProps}
            actionButtonPanel={(events, schedulerActions) => customFooter(events, schedulerActions)}
            dateHeaderTemplate={dateHeaderTemplate}
            dataSource={schedules}
            eventFields={eventFields}
            eventLookupList='programmes'
            eventTemplate={eventTemplate}
            heightBuffer={230}
            loading={isLoading}
            onEventCreate={handleOnEventCreate}
            onEventDrag={handleDrag}
            onExport={handleOnExport}
            onImport={handleOnImport}
            onSlotDeleted={handleDelete}
            onSlotRepeat={handleRepeat}
            onSlotModified={handleOnSlotModified}
            onSlotResizeStop={handleDrag}
            schedule={{ start: startDate, end: endDate }}
            settings={['SLOT DURATION', 'SLOT INTERVAL', 'CLOCKTYPES']}
            suppressOverlappingSchedules={true}
            suppressQuickInfo={true}
            suppressTooltip={true}
            timeoutDelay={80}
          />
        )}
    </>
  );
}

export default CAProgrammmes;
